import Stepper from 'shared/ProgressBar/ProgressBar';
import HeaderNew from 'widgets/signUp/HeaderNew';

import { HeaderProps } from './header.types';

const Header: React.FC<HeaderProps> = ({ flowLength, step, onClickPrev, isKrogerPatient }) => {
  return (
    <div className="sticky top-0 z-10 w-screen max-w-full bg-gray-100 pb-6">
      <div className="flex flex-col-reverse gap-10 md:mb-8 md:flex-col md:gap-6 md:pb-0">
        <Stepper color="primary" step={step + 1} totalSteps={flowLength} rounded />
        <HeaderNew clickOnPrev={onClickPrev} isKrogerPatient={isKrogerPatient} />
      </div>
    </div>
  );
};

export default Header;
