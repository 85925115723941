import { useToggle } from 'react-use';
import { Common } from '@thecvlb/design-system/lib/src';
import classNames from 'classnames';

import { getIdealRangeText, getLabResultStatusForTag } from './labsResultMetricCard.settings';
import { LabsResultMetricCardProps } from './labsResultMetricCard.types';
import RangeScale from './RangeScale';

const LabsResultMetricCard: React.FC<LabsResultMetricCardProps> = ({
  testName,
  testDescription,
  value,
  units,
  status,
  referenceMin,
  referenceMax
}) => {
  const [isExpanded, setIsExpanded] = useToggle(false);

  const referenceMinNumber = !!Number(referenceMin) ? Number(referenceMin) : null;
  const referenceMaxNumber = !!Number(referenceMax) ? Number(referenceMax) : null;
  const numberOfValue = !!Number(value) ? Number(value) : null;
  const statusData =
    (referenceMinNumber || referenceMaxNumber) &&
    value &&
    getLabResultStatusForTag(status, value, referenceMin, referenceMax);

  const idealRangeText = getIdealRangeText(units, referenceMinNumber, referenceMaxNumber);

  const plusClasses = classNames('size-4 text-gray-300 transition', { 'rotate-45': isExpanded });

  const handleExtendMetricIfo = () => {
    setIsExpanded();
  };

  const titleClasses = classNames('text-gray-700', {
    'col-span-2': !referenceMinNumber && !referenceMaxNumber
  });

  return (
    <div className="relative grid h-fit grid-cols-2 items-center gap-4 rounded-2xl bg-white p-6 md:border md:border-gray-200">
      {(referenceMinNumber || referenceMaxNumber) && (
        <button className="absolute right-4 top-4" onClick={handleExtendMetricIfo}>
          <Common.Icon className={plusClasses} name="plus" />
        </button>
      )}
      <div className={titleClasses}>
        <p className="text-mBase font-semibold">{testName}</p>
        <p className="mb-1 text-mLg font-bold first-letter:uppercase">
          {value} <span className="text-mSm font-semibold">{units}</span>
        </p>
        {statusData && <Common.ColorTag {...statusData} />}
      </div>
      {(referenceMinNumber || referenceMaxNumber) && numberOfValue && (
        <RangeScale
          referenceMax={referenceMaxNumber}
          referenceMin={referenceMinNumber}
          value={numberOfValue}
        />
      )}
      {isExpanded && (
        <div className="col-span-2 text-mSm font-medium text-gray-500">
          <p>{idealRangeText}</p>
          {testDescription && <p className="mt-4">{testDescription}</p>}
        </div>
      )}
    </div>
  );
};

export default LabsResultMetricCard;
