import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTitle, useToggle } from 'react-use';
import { Common } from '@thecvlb/design-system';
import fileDownload from 'js-file-download';
import { nanoid } from 'nanoid';

import { useGetMyAccountQuery } from 'services/myAccount/myAccount';
import { useGetOrdersQuery } from 'services/orders/orders';
import {
  useChangeReportsStatusMutation,
  useGetReportsQuery,
  useLazyGetReportQuery
} from 'services/reports/reports';
import { GetReportResProps } from 'services/reports/reports.types';
import { useGetWMOrdersQuery } from 'services/wm/wm';

import { selectOrders, selectReports } from 'store';
import { OrdersItemProps } from 'store/orders/orders.types';
import { ReportsItemProps } from 'store/reports/reports.types';

import MemberPricingBanner from 'features/MemberPricingBanner';
import MyChartTitle from 'features/MyChartTitle';
import RequestedLabModal from 'modals/RequestedLab';
import FadeWrapper from 'shared/animationWrappers/FadeWrapper';
import Loader from 'shared/Loader';
import Document from 'widgets/myChart/Labs/Document';
import RequestedLab from 'widgets/myChart/Labs/RequestedLab';

import { useAppSelector } from 'hooks';
import useAnalytics from 'hooks/useAnalytics';
import useWeightManagement from 'hooks/useWeightManagement';
import useWidth from 'hooks/useWidth';
import { REQUESTED_LAB_LIST } from 'utils/constants';
import { PathName, ShippingStatuses } from 'utils/enums';
import { openAshLink } from 'utils/helpers';

import { getAshLabKitInfo } from './labs.settings';

const Labs = () => {
  useTitle('LifeMD - Labs & Imaging');
  const navigate = useNavigate();
  const logEvent = useAnalytics();
  const { isWeightManagement } = useWeightManagement();
  const { isLoading: ReportsLoading } = useGetReportsQuery();
  const { isLoading: OrdersLoading, refetch } = useGetOrdersQuery();
  const [getReportQuery, { originalArgs, isFetching: GetReportLoading }] = useLazyGetReportQuery();
  const [changeStatus] = useChangeReportsStatusMutation();
  const { refetch: refetchMyAccount } = useGetMyAccountQuery();
  const { data: ashData } = useGetWMOrdersQuery({ type: 'Ash' });

  const reports = useAppSelector(selectReports);
  const { orders } = useAppSelector(selectOrders);
  const { isMobile } = useWidth();

  const [isOpen, toggleIsOpen] = useToggle(false);
  const [selectedOrder, setSelectedOrder] = useState<OrdersItemProps | null>(null);
  const [pending, setPending] = useState<boolean>(false);
  const combinedLoading = ReportsLoading || OrdersLoading;

  const handleOnClose = () => {
    if (!pending) {
      refetchMyAccount();
      refetch();
    }
    toggleIsOpen();
  };

  const handleClickRequestedLabsBanner = (order: OrdersItemProps, pendingStatus: boolean) => {
    setSelectedOrder(order);
    setPending(pendingStatus);
    toggleIsOpen();
  };

  const handleGetReport = (report: ReportsItemProps) => {
    getReportQuery({ reportId: report.reportId })
      .unwrap()
      .then((res: GetReportResProps) => {
        fileDownload(res, `${report.title || report.reportType}.pdf`);
      });
  };

  const handleClickViewedOrder = (order: OrdersItemProps) => {
    logEvent('labs_and_imaging_req_order_item_click');
    handleClickRequestedLabsBanner(order, true);
  };

  const handleClickNewOrder = (order: OrdersItemProps) => {
    handleClickRequestedLabsBanner(order, false);
  };

  const handleClickDownloadReport = (report: ReportsItemProps) => {
    logEvent('labs_and_imaging_result_item_click');
    handleGetReport(report);
  };

  const handleViewReport = (report: ReportsItemProps) => {
    if (report?.reportId) {
      navigate(`${PathName.Labs}/${report?.reportId}`);
    }
  };

  const handleUpdatedReports = () => {
    const resultIdsList = reports
      .filter((item) => item.status !== 'viewed')
      .map((item) => item._id);
    if (resultIdsList.length) {
      changeStatus({
        resultIdsList,
        resultStatus: 'viewed'
      })
        .unwrap()
        .then(refetchMyAccount);
    }
  };

  useEffect(handleUpdatedReports, [reports]);

  const ashOrder = ashData?.data.find((order) => order.type === 'Ash');

  const newOrders = orders.map(
    (order) =>
      !!order.items.find((item) => !item.isViewed) && (
        <RequestedLab
          date={order.date}
          key={nanoid()}
          unread={order.items.filter((item) => !item.isViewed).length}
          onClick={() => handleClickNewOrder(order)}
        />
      )
  );
  const viewedOrders = orders.map(
    (order) =>
      !!order.items.find((item) => item.isViewed) && (
        <RequestedLab
          date={order.date}
          key={nanoid()}
          pending
          onClick={() => handleClickViewedOrder(order)}
        />
      )
  );

  const subheaderClassName = 'text-mBase font-bold text-primary md:text-lg md:text-gray-700';

  return (
    <FadeWrapper className="flex flex-col md:p-8">
      <Loader isVisible={combinedLoading} />
      <RequestedLabModal
        date={selectedOrder?.date}
        isOpen={isOpen}
        pending={pending}
        stepsList={REQUESTED_LAB_LIST}
        onClose={handleOnClose}
      />
      <MyChartTitle icon="experiment" text="Labs & imaging" />
      <div className="md:mt-8 md:flex md:gap-8">
        <div
          className="flex-1 rounded-2xl border border-gray-200 bg-white p-4 md:border-none md:p-0"
          data-testid="labs_imaging_content"
        >
          {(!!orders.length ||
            (!!ashOrder && ashOrder.shippingStatus !== ShippingStatuses.ASH_RESULTS_READY)) && (
            <div className="flex flex-col" data-testid="requested">
              <p className={`${subheaderClassName} mb-3 md:mt-4`}>Requested</p>
              {ashOrder && (
                <button
                  className="flex w-full items-center gap-3 rounded-2xl bg-gray-100 px-4 py-2"
                  onClick={openAshLink}
                >
                  <Common.Icon className="text-primary" name="clock" />
                  <div className="flex flex-col">
                    <span className="block text-start font-bold">At-home lab kit</span>
                    <span className="block text-start text-mSm text-gray">
                      {getAshLabKitInfo(ashOrder.shippingStatus)}
                    </span>
                  </div>
                  <Common.Icon className="ml-auto text-gray" name="arrow-right" />
                </button>
              )}
              {newOrders}
              {viewedOrders}
            </div>
          )}
          <p className={`${subheaderClassName} my-4 md:mb-0 md:mt-6`} data-testid="results_heading">
            Results
          </p>
          <div className="divide-y" data-testid="results_section">
            {reports.map((report) => (
              <Document
                dataTestId="result_doc"
                date={report.signedDate}
                key={report._id}
                loading={GetReportLoading && originalArgs?.reportId === report.reportId}
                title={report.title || report.reportType}
                onDownload={() => handleClickDownloadReport(report)}
                {...(!!report.results?.length && { onView: () => handleViewReport(report) })}
              />
            ))}
          </div>
          {!reports.length && (
            <div
              className="flex flex-col items-center px-8 py-12 text-center md:py-8"
              data-testid="lab_results"
            >
              <Common.Icon className="size-14 md:size-16" name="lifemd" />
              <h1 className="mt-4 font-bold">You don’t have any lab results.</h1>
              <h2 className="text-gray">
                Once you complete your labs and the results are in, they’ll appear here.
              </h2>
            </div>
          )}
        </div>
        {!isMobile && !isWeightManagement && <MemberPricingBanner />}
      </div>
    </FadeWrapper>
  );
};

export default Labs;
