import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Common } from '@thecvlb/design-system/lib/src';
import isEmpty from 'lodash/isEmpty';

import {
  useGetCreditCardInformationQuery,
  useUpdateDefaultPaymentMethodMutation
} from 'services/myAccount/myAccount';
import { useStoreCheckoutMutation } from 'services/shop/shop';
import { CheckoutResProps } from 'services/shop/shop.types';

import { selectShop, selectUser } from 'store';
import { resetStore, setShippingAddress } from 'store/shop/shopSlice';

import ShippingTo from 'pages/shop/Checkout/components/ShippingTo';
import SummaryCard from 'pages/shop/Checkout/components/SummaryCard';

import PaymentMethods from 'features/PaymentMethods';
import EncryptedBadge from 'shared/EncryptedBadge';
import Loader from 'shared/Loader';
import { notifySuccess } from 'shared/Toast/Toast';

import { useAppDispatch, useAppSelector } from 'hooks';
import { PathName } from 'utils/enums';
import { handleRequestCatch } from 'utils/helpers';

import { PaymentMethod } from 'models/payments.types';

const Checkout = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { shippingAddress } = useAppSelector(selectShop);
  const { address, state, city, zipCode } = useAppSelector(selectUser);

  const [cartData, setCartData] = useState<CheckoutResProps['data'] | null>(null);

  const { data, isFetching, isLoading, refetch } = useGetCreditCardInformationQuery();
  const [updateDefaultPaymentMethod, { isLoading: isUpdatingDefaultPaymentMethod }] =
    useUpdateDefaultPaymentMethodMutation();

  const [checkout, { isLoading: isLoadingCheckout }] = useStoreCheckoutMutation();

  const handleFavoritePaymentMethod = async (id: string, cb?: () => void) => {
    if (!id) return;
    try {
      const response = await updateDefaultPaymentMethod({
        id
      }).unwrap();
      await refetch().unwrap();
      notifySuccess(response.message ?? 'Updated default payment method');
      cb?.();
    } catch (e) {
      handleRequestCatch(e as MessageEvent);
    }
  };

  const getPaymentMethods = (): PaymentMethod[] => {
    return (
      data?.data?.map((paymentMethod) => ({
        ...paymentMethod,
        type: 'membership'
      })) ?? []
    );
  };

  const handleSubmit = () => {
    checkout({
      processPayment: true,
      shippingAddress,
      multiple: true
    })
      .unwrap()
      .then((res) => {
        if (res.data) {
          const orderIds = res.data.orders?.map((order) => order.orderId);
          navigate(PathName.ShopOrders + '/' + orderIds?.join(','));
          dispatch(resetStore());
        }
      })
      .catch(handleRequestCatch);
  };

  const headingClassName = 'font-bold md:text-xl md:text-primary-700';
  const addressToShow = `${shippingAddress.address} ${shippingAddress.city}, ${shippingAddress.state} ${shippingAddress.zipCode}`;

  useEffect(() => {
    if (!shippingAddress.address) {
      dispatch(
        setShippingAddress({
          address,
          city,
          state,
          zipCode
        })
      );
    }
  }, []);

  useEffect(() => {
    !!shippingAddress.address &&
      checkout({
        processPayment: false,
        shippingAddress
      })
        .unwrap()
        .then((res) => setCartData(res.data));
  }, [shippingAddress]);

  useEffect(() => {
    if (!!cartData && isEmpty(cartData.items)) {
      navigate(PathName.WeightManagement);
    }
  }, [cartData]);

  return (
    <>
      <Loader isVisible={isLoadingCheckout} />

      <div className="flex h-full flex-col gap-4 md:mx-auto md:max-w-[420px] md:gap-8">
        <h2
          className="text-center text-2xl font-bold text-primary-700 max-md:hidden"
          data-testid="header"
        >
          Confirm and pay
        </h2>
        {!!cartData && !isEmpty(cartData.items) && (
          <div className="flex flex-col gap-4">
            <h2 className={headingClassName} data-testid="summary_header">
              Items
            </h2>
            <SummaryCard items={cartData.items} total={cartData.total} />
          </div>
        )}
        <div className="flex flex-col gap-4">
          <h2 className={headingClassName} data-testid="ship_to_header">
            Ship to
          </h2>
          <ShippingTo address={addressToShow} onClickEdit={() => navigate(PathName.ShopAddress)} />
        </div>
        <div className="flex flex-col gap-4">
          <h2 className={headingClassName} data-testid="payment_header">
            Payment
          </h2>
          <div className="flex flex-col gap-4" data-testid="payment_section">
            {!isLoading && (
              <PaymentMethods
                isLoading={isFetching}
                paymentMethods={getPaymentMethods()}
                onAddMethod={refetch}
                onUpdateFavoritePaymentMethod={handleFavoritePaymentMethod}
              />
            )}
          </div>
        </div>

        {!isEmpty(cartData?.items) && (
          <div className="flex flex-col gap-4 max-md:mt-auto max-md:py-6">
            <Common.Button
              className="!w-full"
              color="blue"
              dataTestId="confirm_payment_btn"
              disabled={isUpdatingDefaultPaymentMethod || isLoadingCheckout}
              fullWidthOnMobile
              onClick={handleSubmit}
            >
              Confirm ${cartData?.total} payment
            </Common.Button>
            <EncryptedBadge />
          </div>
        )}
      </div>
    </>
  );
};

export default Checkout;
