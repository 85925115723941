import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { EmailStatus } from 'utils/enums';

import { UserState } from './user.types';

export const initialState: UserState = {
  _id: '',
  dynamicLink: '',
  elationId: '',
  hasPasswordResetToken: false,
  hasSubscription: false,
  lastLoginClientPlatform: '',
  nickName: '',
  status: '',
  brazeExternalId: '',
  globalSettings: [],
  hasInsurance: false,
  miCompletionStatus: false,
  aboutMe: '',
  accessToken: '',
  activePlanCode: '',
  activePlanId: '',
  activePlanName: '',
  activePricePoint: '',
  address: '',
  availableReview: false,
  changePassword: null,
  city: '',
  companyPartners: { connectedPartners: [], onboardingPartner: null },
  customerId: '',
  dob: '',
  doctorId: '',
  email: '',
  emailStatus: EmailStatus.LEGACY,
  features: {
    annual45: { status: 'disabled' },
    choosingDoctor: { status: 'disabled' },
    complimentaryMedications: { status: 'disabled' },
    documentsAndLinks: { status: 'enabled' },
    frontDesk: { status: 'disabled' },
    healthProfile: { status: 'enabled' },
    labsAndImaging: { status: 'enabled' },
    medicalRecords: { status: 'enabled' },
    messaging: { status: 'disabled' },
    myProvider: { status: 'enabled' },
    migrateToBundlePlan: { status: 'enabled' },
    prescriptionDiscountCard: { status: 'disabled' },
    prescriptions: { status: 'disabled' },
    reOnboardingWM: { status: 'disabled' },
    scheduleAppointment: { status: 'disabled' },
    symptomChecker: { status: 'enabled' },
    store: { status: 'disabled' }
  },
  firstName: '',
  gender: '',
  hadOrHasWeightManagement: false,
  hasActiveOrders: 0,
  hasActiveResults: 0,
  hasPassword: true,
  identityVerified: false,
  identityVerifiedAt: null,
  initialLoginVideo: '',
  isAnnualMembership: false,
  isEmailVerified: false,
  isFirstAppointmentCompleted: false,
  isInitialLogin: false,
  isUnlimitedPlan: false,
  isVerifiedByVouched: null,
  lastLogin: '',
  lastName: '',
  lastSentRating: 0,
  medicalIntakeCompleted: false,
  nextPlanCode: '',
  nextSubscriptionRenewalAt: null,
  onboardingTask: { _id: '', category: '', status: '' },
  patientPreferences: null,
  phone: {
    countryCode: '',
    phoneNumber: '',
    countryPrefix: '',
    internationalFormat: ''
  },
  profileImage: '',
  refreshToken: '',
  registrationDate: '',
  reviewedProviders: [],
  sexAtBirth: '',
  state: '',
  subscriptionAutomaticallyResumeAt: null,
  subscriptionId: '',
  subscriptionOnHold: false,
  timezone: '',
  userId: '',
  verifyEmail: null,
  zipCode: '',
  paymentProfiles: [],
  pharmacy: {
    _id: '',
    address: '',
    city: '',
    fax: '',
    latitude: 0,
    longitude: 0,
    name: '',
    pharmacyId: 0,
    phone: '',
    state: '',
    zipCode: ''
  }
};

const userSlice = createSlice({
  initialState,
  name: 'user',
  reducers: {
    clearUser: (): UserState => initialState,
    setToken: (
      state,
      {
        payload: { accessToken, refreshToken }
      }: PayloadAction<{ accessToken: string; refreshToken: string }>
    ) => {
      state.accessToken = accessToken;
      state.refreshToken = refreshToken;
    },
    setUser: (state, action: PayloadAction<Partial<UserState>>) => {
      if (action.payload._id) {
        action.payload = { ...action.payload, userId: action.payload._id };
      }

      return {
        ...state,
        ...action.payload,
        // to remove unpaid invoice notification when user pays the invoice
        hasUnpaidInvoice: action.payload.hasUnpaidInvoice
        // companyPartners: {
        //   ...state.companyPartners,
        //   onboardingPartner: {
        //     partnerName: 'Kroger'
        //   }
        // }
      };
    }
  }
});

export const { setUser, setToken, clearUser } = userSlice.actions;

export default userSlice.reducer;
