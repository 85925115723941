import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { PathName } from 'utils/enums';

import { ProductsListProps } from './productsList.types';

const ProductsList: React.FC<ProductsListProps> = ({ products, bgWhite }) => {
  return (
    <div
      className={classNames('grid gap-3 md:gap-4', {
        'grid-cols-2': !(products.length % 1)
      })}
    >
      {products.map((item) => (
        <Link
          className="group flex flex-col gap-1"
          key={item._id}
          to={PathName.ShopProducts + `/${item._id}`}
        >
          <div
            className={classNames(
              'relative rounded-xl p-6 transition-all group-hover:shadow',
              bgWhite ? 'bg-white' : 'bg-white md:bg-gray-100'
            )}
            data-testid="product_item"
          >
            {item?.label && (
              <div className="absolute right-4 top-4 rounded-[4px] bg-blue-100 px-2 py-1 text-sm font-semibold text-blue-600">
                {item.label}
              </div>
            )}
            <img
              alt={item.name}
              className="mx-auto h-[115px] max-w-full object-contain transition-all group-hover:scale-[102%]"
              data-testid="product_img"
              src={item.featuredAsset.url}
            />
          </div>
          <div>
            <h4 className="text-mBase font-semibold text-black">{item.name}</h4>
            <span className="text-mSm text-gray">{item.type}</span>
            <p>
              {Number(item.msrpPrice) > Number(item.price.unitPrice) ? (
                <span className="text-mSm text-green">
                  ${item.price.unitPrice}{' '}
                  <span className="mr-1 text-gray line-through">${item.msrpPrice}</span>
                </span>
              ) : (
                <span className="text-mSm text-green">${item.price.unitPrice}</span>
              )}
            </p>
          </div>
        </Link>
      ))}
    </div>
  );
};

export default ProductsList;
