import Loader from 'shared/Loader';
import Header from 'widgets/Header';

import { usePostOnboarding } from 'hooks/usePostOnboarding';

import usePartnerPatient from '../../hooks/usePartnerPatient';

import Content from './Content';

const PostOnboarding = () => {
  const {
    steps,
    currentStep,
    moveToStep,
    loading,
    isFetching,
    makeCrossCheck,
    handleSelectInsurance,
    isBackAnimation
  } = usePostOnboarding();

  const isKrogerPatient = usePartnerPatient('Kroger');

  if (loading) return <Loader isVisible />;

  return (
    <>
      <Header
        flowLength={steps.length}
        isKrogerPatient={isKrogerPatient}
        step={steps.indexOf(currentStep)}
        onClickPrev={() => moveToStep('prev')}
      />
      <div className="relative grow p-4">
        <Content
          isBackAnimation={isBackAnimation}
          loading={isFetching}
          moveToStep={moveToStep}
          step={currentStep}
          steps={steps}
          onCompleteAddress={makeCrossCheck}
          onSelectInsurance={handleSelectInsurance}
        />
      </div>
    </>
  );
};

export default PostOnboarding;
