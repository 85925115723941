import { ReactElement } from 'react';
import classNames from 'classnames';
import { AnimatePresence } from 'framer-motion';

import { selectNewAppointment, selectUser } from 'store';

import SlideAnimateWrapper from 'shared/animationWrappers/SlideAnimateWrapper';
import AttachFiles from 'widgets/appointments/AttachFiles';
import Confirmation from 'widgets/appointments/Confirmation';
import MedicalCareNear from 'widgets/appointments/MedicalCareNear';
import Membership from 'widgets/appointments/Membership';
import RedFlags from 'widgets/appointments/RedFlags';
import CategoryAndDetails from 'widgets/CategoryAndDetails';
import DateAndTime from 'widgets/DateAndTime';
import PaymentCheckout from 'widgets/payment/PaymentCheckout';

import { useAppSelector } from 'hooks';

import { PossibleStepsType } from '../createAppointment.types';

import { Props } from './content.types';

const renderWithAnimation = (
  component: JSX.Element,
  key: string,
  params?: {
    className?: string;
    isBackAnimation?: boolean;
  }
) => (
  <SlideAnimateWrapper
    className={classNames('mx-auto w-full max-md:h-full', params?.className ?? '')}
    isBackAnimation={!!params?.isBackAnimation}
    key={key}
    src="signup"
  >
    {component}
  </SlideAnimateWrapper>
);

const Content: React.FC<Props> = ({
  currentPlan,
  loading = false,
  step,
  moveToStep,
  isBackAnimation,
  fillAppointmentData,
  onSelectDateAndTime,
  selectedPlan,
  onUpgradePlan
}) => {
  const { accessToken, doctorId: myDoctorID } = useAppSelector(selectUser);
  const {
    appointmentTypeId,
    appointmentDescription,
    initialDate,
    startTime,
    endTime,
    _id,
    displayName,
    doctorId,
    files,
    membershipData = { newPlanID: '', freeAppointmentInfo: undefined, newPP: null }
  } = useAppSelector(selectNewAppointment);

  const content: { [key in PossibleStepsType[number]]: ReactElement } = {
    category: renderWithAnimation(
      <CategoryAndDetails
        appointmentDescription={appointmentDescription}
        appointmentTypeId={appointmentTypeId}
        icon="large-calendar"
        title="What is this appointment for?"
        onClickBack={() => moveToStep('prev')}
        onClickNext={(data) => fillAppointmentData({ step: 'category', data })}
      />,
      'category',
      { isBackAnimation, className: 'md:max-w-[420px] md:mx-auto' }
    ),
    checkout: renderWithAnimation(
      currentPlan ? (
        <PaymentCheckout
          category={displayName}
          existingPlan={currentPlan}
          freeAppointmentInfo={membershipData?.freeAppointmentInfo}
          loading={loading}
          providerName={displayName}
          selectedPlan={selectedPlan || currentPlan}
          selectedPricePoint={membershipData?.newPP}
          time={startTime}
          onProceed={onUpgradePlan}
        />
      ) : (
        <>No selected plan</>
      ),
      'checkout',
      { isBackAnimation }
    ),
    confirmation: renderWithAnimation(
      <Confirmation
        appointment={{
          apptName: displayName,
          description: appointmentDescription,
          endTime: endTime,
          id: _id ?? '',
          startTime: startTime,
          title: displayName || 'Your appointment is scheduled'
        }}
        providerId={doctorId}
        providerName={displayName}
      />,
      'confirmation',
      { isBackAnimation }
    ),
    'date-time': renderWithAnimation(
      <DateAndTime
        accessToken={accessToken}
        appointmentTypeId={appointmentTypeId}
        doctorId={myDoctorID}
        initialDate={initialDate}
        loading={loading}
        providerType={!!initialDate ? 'any' : undefined}
        updateStartDate={(_, value, valueEnd, id) => {
          fillAppointmentData({
            step: 'date-time',
            data: {
              startTime: value,
              endTime: valueEnd,
              doctorId: id || myDoctorID
            }
          });
        }}
        onSelect={onSelectDateAndTime}
      />,
      'date-time',
      { isBackAnimation }
    ),
    files: renderWithAnimation(
      <AttachFiles
        files={files ?? []}
        setFiles={(f) => {
          fillAppointmentData({
            step: 'files',
            data: {
              files: f
            }
          });
        }}
        onBack={() => moveToStep('prev')}
        onContinue={() => moveToStep('next')}
      />,
      'files',
      {
        isBackAnimation,
        className: 'flex flex-col h-full'
      }
    ),
    'medical-care-near': renderWithAnimation(<MedicalCareNear />, 'medical-care-near', {
      isBackAnimation
    }),
    membership: (
      <Membership
        loading={loading}
        selectedPlanId={membershipData?.newPlanID}
        startTime={startTime}
        onClickBack={() => moveToStep('prev')}
        onClickContinue={() => moveToStep('next')}
        onSelect={(data) => {
          const mappedData = { ...data, newPlanID: data.planID, newPP: data.pp };
          fillAppointmentData({
            step: 'membership',
            data: mappedData
          });
        }}
      />
    ),
    intro: renderWithAnimation(
      <RedFlags
        loading={loading}
        onSelect={() => moveToStep('category')}
        onSkip={() => moveToStep('prev')}
      />,
      'red-flags',
      { isBackAnimation }
    )
  };

  return (
    <div className="mx-auto flex w-full grow flex-col px-0.5 max-md:h-full">
      <AnimatePresence mode="wait">{content[step]}</AnimatePresence>
    </div>
  );
};

export default Content;
