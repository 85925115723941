import { LabsType } from 'services/wm/wm.types';

import RadioGroup from 'shared/RadioGroup';

import { DUMMY_PRICE_OF_AT_HOME_LABS } from 'constants/defaults';

import { LabsToCheckoutProps } from './labsToCheckout.types';

import LabKit from 'assets/images/signUp/lab-kit.webp';

const OPTIONS = [
  {
    label: (
      <div className="flex w-full items-center justify-between gap-2">
        <div className="flex flex-col gap-1">
          <span className="text-mSm font-bold text-primary-400">
            Only ${DUMMY_PRICE_OF_AT_HOME_LABS}
          </span>
          <span className="font-semibold">At-home lab kit</span>
          <span className="text-mSm text-gray">Shipping included</span>
        </div>
        <img alt="labs-at-home" className="size-16" src={LabKit} />
      </div>
    ),
    value: LabsType.ash
  },
  {
    label: (
      <div className="flex justify-between gap-2">
        <div className="flex flex-col gap-1">
          <span className="text-mSm text-gray">No additional cost</span>
          <span className="font-semibold">I’ll go to a lab near me</span>
        </div>
      </div>
    ),
    value: LabsType.elation
  }
];

const LabsToCheckout: React.FC<LabsToCheckoutProps> = ({
  activeItem,
  setActiveItem,
  isLoading
}) => {
  return (
    <RadioGroup
      isLoading={isLoading}
      items={OPTIONS}
      selectedItem={activeItem}
      onSelect={setActiveItem}
    />
  );
};

export default LabsToCheckout;
