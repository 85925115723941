import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { notifySuccess } from 'shared/Toast/Toast';
import KrogerConnectID from 'widgets/KrogerConnectID';

import useKrogerIDConnected from 'hooks/useKrogerIDConnected';
import { PathName } from 'utils/enums';

const KrogerConnectIDPage = () => {
  const navigate = useNavigate();
  const { isKrogerIDConnected, isFetching } = useKrogerIDConnected();

  useEffect(() => {
    if (isKrogerIDConnected) {
      notifySuccess('Your Kroger ID is already connected!');
      navigate(PathName.WeightManagement);
    }
  }, [isKrogerIDConnected]);

  return (
    <div className="max-md:h-full md:pt-10">
      <KrogerConnectID
        loading={isFetching}
        onContinue={() => navigate(PathName.WeightManagement)}
        onRefuse={() => navigate(-1)}
      />
    </div>
  );
};

export default KrogerConnectIDPage;
