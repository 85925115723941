import { selectUser } from 'store';

import KrogerMap from 'widgets/KrogerMap';

import { LINKS_TO_KROGER_PLUS } from 'constants/externalLinks';
import { useAppSelector } from 'hooks';

const KrogerLocations = () => {
  const { zipCode, city, address, state } = useAppSelector(selectUser);

  return (
    <>
      <KrogerMap
        isLoading={false}
        mailingAddress={{
          address,
          city,
          state,
          zipCode
        }}
        onContinue={() => {}}
        onSelect={() =>
          window.open(LINKS_TO_KROGER_PLUS.shops_list, '_blank', 'noopener noreferrer')
        }
      />
    </>
  );
};

export default KrogerLocations;
