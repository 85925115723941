import { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { useGetSet, useToggle } from 'react-use';
import { Status, Wrapper } from '@googlemaps/react-wrapper';
import { Common } from '@thecvlb/design-system';
import debounce from 'lodash/debounce';
import uniqBy from 'lodash/uniqBy';

import { useLazyGetKrogerShopsQuery } from 'services/kroger/kroger';
import { GetKrogerStoresReq, ShopItem } from 'services/kroger/kroger.types';

import { selectGlobalSettings } from 'store';

import GoogleMap from 'features/GoogleMap';
import Marker from 'features/GoogleMap/Marker';
import FadeWrapper from 'shared/animationWrappers/FadeWrapper';
import Loader from 'shared/Loader';
import { ItemsList } from 'widgets/KrogerMap/components';
import { ActionsCard } from 'widgets/KrogerMap/components';

import { useAppSelector } from 'hooks';
import useAnalytics from 'hooks/useAnalytics';
import useWidth from 'hooks/useWidth';
import { LOS_ANGELES_COORDS, MAP_STYLES } from 'utils/constants';
import { handleRequestCatch } from 'utils/helpers';

import { getIcon } from './krogerMap.settings';
import { KrogerMapProps } from './krogerMap.types';

import LocatorSvg from 'assets/images/map/locator.svg';

const render = (status: Status): ReactElement => {
  if (status === Status.FAILURE) return <>Error with map loading, please try again</>;
  return <Loader isVisible />;
};

const KrogerMap: React.FC<KrogerMapProps> = ({
  mailingAddress,
  onSelect,
  onDeselect,
  isLoading,
  onContinue,
  displayKrogerPreview
}) => {
  const logEvent = useAnalytics();
  const { isMobile } = useWidth();
  const [isOpen, setIsOpen] = useToggle(true);
  const mapRef = useRef(null);
  const [lastSearchType, setLastSearchType] = useState('');
  const [showRedoButton, toggleRedoButton] = useState(false);
  const [shouldBound, setShouldBound] = useState(false);
  const [userLocation, setUserLocation] = useState<google.maps.LatLngLiteral | undefined>();
  const { krogerNearbyDistance } = useAppSelector(selectGlobalSettings);

  const [getKrogerShops, { isFetching }] = useLazyGetKrogerShopsQuery();

  const [selectedPharmacy, setSelectedPharmacy] = useGetSet<ShopItem | null>(null);
  const [typeOnPause, setTypeOnPause] = useState(false);
  const [search, setSearch] = useGetSet('');
  const [clicks, setClicks] = useState<google.maps.LatLng[]>([]);
  const [zoom, setZoom] = useState(12); // initial zoom
  const [center, setCenter] = useState<google.maps.LatLngLiteral>(LOS_ANGELES_COORDS);
  // const [userLocation, setUserLocation] = useState<google.maps.LatLngLiteral | undefined>();
  const [pharmaciesList, setPharmaciesList] = useState<ShopItem[]>([]);

  const GOOGLE_MAP_WIDTH = mapRef?.current
    ? (mapRef?.current as unknown as HTMLDivElement).offsetWidth / 2
    : 216;

  const [searchBody, setSearchBody] = useGetSet<Omit<GetKrogerStoresReq, 'zoom' | 'distance'>>({
    address: undefined,
    latitude: 0,
    longitude: 0,
    pixels: GOOGLE_MAP_WIDTH
  });

  const searchShops = (initial = false) => {
    const body = initial
      ? { distance: krogerNearbyDistance, address: searchBody().address }
      : { ...searchBody(), zoom };
    if (
      search().length < 3 &&
      ('distance' in body ? !body.distance : !body.latitude && !body.longitude && !body.address)
    ) {
      return;
    }
    setTypeOnPause(false);
    getKrogerShops({ ...body })
      .unwrap()
      .then(({ data }) => {
        if (initial) {
          setUserLocation({
            lat: data.selectedLocation.latitude,
            lng: data.selectedLocation.longitude
          });
        }
        toggleRedoButton(false);
        setLastSearchType(body.address ? 'address' : '');
        if (body.address && data.stores.length) {
          setShouldBound(true);
        }
        data.selectedLocation &&
          setCenter({
            lat: data.selectedLocation.latitude,
            lng: data.selectedLocation.longitude
          });
        setPharmaciesList(uniqBy([...data.stores], '_id'));
        if (!data.stores.length) {
          setTypeOnPause(true);
        }
        setShouldBound(false);
      })
      .catch((e) => {
        toggleRedoButton(true);
        handleRequestCatch(e, 'Entered location not found');
      });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    if (typeOnPause) {
      setTypeOnPause(false);
    }
  };

  const debouncedHandleChange = useMemo(
    () =>
      debounce((v) => {
        setSearchBody(v);
        searchShops();
      }, 2500),
    []
  );

  const onClick = (e: google.maps.MapMouseEvent) => {
    setIsOpen(false);
    setClicks([...clicks, e.latLng!]);
  };

  const updateSearchBody = (m: google.maps.Map) => {
    toggleRedoButton(true);
    setSearchBody({
      latitude: m.getCenter()!.toJSON().lat,
      longitude: m.getCenter()!.toJSON().lng,
      pixels: GOOGLE_MAP_WIDTH
    });
  };
  const onIdle = (m: google.maps.Map) => {
    if (lastSearchType === 'address') {
      setLastSearchType('');
      return;
    }
    if (zoom !== m.getZoom()!) {
      updateSearchBody(m);
      setZoom(m.getZoom()!);
    } else if (
      (center.lat && Math.abs(center.lat - m.getCenter()!.toJSON().lat) > 0.01) ||
      Math.abs(center.lng - m.getCenter()!.toJSON().lng) > 0.01
    ) {
      setCenter(m.getCenter()!.toJSON());
      updateSearchBody(m);
    }
  };

  const handleClickSearch = () => {
    logEvent('pharmacies_redo_search_btn_click');
    searchShops();
  };

  const handleClickPharmacy = (id: string) => {
    const newSelectedPharmacy =
      selectedPharmacy()?._id === id
        ? selectedPharmacy() || null
        : pharmaciesList.find((p) => p._id === id) || null;

    setSelectedPharmacy(newSelectedPharmacy);
    if (selectedPharmacy()?._id === id) {
      setIsOpen(true);
    }
  };

  const handleChoosePharmacy = () => {
    logEvent('pharmacies_choose_btn_click');
    onSelect(selectedPharmacy()?._id);
  };

  const handleDeselect = () => {
    logEvent('pharmacies_deselect_btn_click');
    onDeselect?.(selectedPharmacy()?._id);
  };

  useEffect(() => {
    setSearchBody({
      address: `${mailingAddress.address}, ${mailingAddress.city}, ${mailingAddress.state}, ${mailingAddress.zipCode}`,
      latitude: center.lat,
      longitude: center.lng,
      pixels: GOOGLE_MAP_WIDTH
    });
    searchShops(true);
  }, []);

  useEffect(() => {
    debouncedHandleChange({
      address: search(),
      pixels: GOOGLE_MAP_WIDTH
    });
  }, [search()]);

  return (
    <FadeWrapper className="relative flex size-full flex-col gap-6 overflow-hidden">
      <Loader isVisible={isFetching} />
      <ItemsList
        displayKrogerPreview={displayKrogerPreview}
        handleInputChange={handleInputChange}
        isFetching={isFetching}
        isOpen={isOpen}
        pharmaciesList={pharmaciesList}
        searchValue={search()}
        selectedPharmacyId={selectedPharmacy()?._id}
        typeOnPause={typeOnPause}
        onClickPharmacy={(id) => {
          logEvent('pharmacies_pharmacy_item_click');
          handleClickPharmacy(id);
        }}
      />
      <div className="relative size-full" ref={mapRef}>
        {showRedoButton && (
          <Common.Button
            className="!absolute inset-x-0 top-20 z-10 mx-auto mb-4 md:left-[350px] md:top-4"
            color="white"
            disabled={isFetching}
            isLoading={isFetching}
            size="sm"
            style="pill"
            onClick={handleClickSearch}
          >
            Redo search in this area
          </Common.Button>
        )}
        <Wrapper
          apiKey={import.meta.env.VITE_GOOGLE_API_KEY || ''}
          language="en"
          region="US"
          render={render}
        >
          <GoogleMap
            center={center}
            mapPoints={pharmaciesList}
            shouldBound={shouldBound}
            style={{ height: '100%', width: !isMobile ? '100%' : '472px' }}
            styles={MAP_STYLES}
            zoom={zoom}
            onClick={onClick}
            onIdle={onIdle}
          >
            {userLocation && (
              <Marker icon={LocatorSvg} key="locator" position={userLocation} onClick={() => {}} />
            )}
            {pharmaciesList.map((pharmacy) => (
              <Marker
                icon={getIcon(pharmacy, isOpen, selectedPharmacy()?._id, isMobile)}
                key={pharmacy._id}
                label={{
                  className: `marker-label ${
                    selectedPharmacy()?._id === pharmacy._id && isOpen && 'marker-label-selected'
                  }`,
                  text: pharmacy.name
                }}
                position={
                  pharmacy.latitude && pharmacy.longitude
                    ? { lat: pharmacy.latitude, lng: pharmacy.longitude }
                    : null
                }
                zIndex={pharmacy._id === selectedPharmacy()?._id ? 10 : 1}
                onClick={() => {
                  logEvent('pharmacies_marker_click');
                  handleClickPharmacy(pharmacy._id);
                }}
              />
            ))}
          </GoogleMap>
        </Wrapper>
        {isOpen && !!selectedPharmacy() && (
          <ActionsCard
            distance={selectedPharmacy()?.distance}
            isLoading={isLoading}
            selectedPharmacy={selectedPharmacy()!}
            onChoose={handleChoosePharmacy}
            onContinue={onContinue}
            onDeselect={handleDeselect}
          />
        )}
      </div>
    </FadeWrapper>
  );
};

export default KrogerMap;
