import { useEffect } from 'react';
import { Common } from '@thecvlb/design-system';
import dayjs from 'dayjs';

import Document from 'widgets/myChart/Documents/Document';

import { DateFormat } from 'utils/enums';

import { DeleteDocumentProps } from './deleteDocument.types';

const DeleteDocument: React.FC<DeleteDocumentProps> = ({
  isOpen,
  onClose,
  onConfirm,
  document,
  isLoading
}) => {
  useEffect(() => {
    const onClickEnter = (e: KeyboardEvent) => {
      if (e.key === 'Enter' && isOpen) {
        onConfirm();
      }
    };
    window.document.addEventListener('keyup', onClickEnter);
    return () => {
      window.document.removeEventListener('keyup', onClickEnter);
    };
  }, []);
  return (
    <Common.Modal close={onClose} isOpen={isOpen} padding={false} size="sm">
      <div className="flex flex-col gap-6 p-4">
        <div>
          <h3 className="mb-2 truncate text-xl font-bold">Delete file: "{document?.fileName}"</h3>
          <p className="text-base font-medium">
            {dayjs(document?.createdAt).format(DateFormat.MMMM_DD_YYYY)} •{' '}
            {document?.isUploadedByCurrentUser ? 'Me' : document?.uploadedBy}
          </p>
        </div>
        {document && <Document document={document} />}
        <div className="flex flex-col-reverse gap-3 md:flex-row">
          <Common.Button
            className="grow"
            color="white-alt"
            dataTestId="cancel_btn"
            disabled={isLoading}
            fullWidthOnMobile
            onClick={onClose}
          >
            Cancel
          </Common.Button>
          <Common.Button
            className="grow"
            color="red"
            dataTestId="delete_on_popup_btn"
            disabled={isLoading}
            fullWidthOnMobile
            onClick={onConfirm}
          >
            Delete
          </Common.Button>
        </div>
      </div>
    </Common.Modal>
  );
};

export default DeleteDocument;
