import { createApi } from '@reduxjs/toolkit/query/react';

import { setGlobalSettings } from 'store/settings/settingsSlice';

import { baseQueryWithReauth } from 'utils/services';

import {
  GetGeneralSettingsRes,
  GetSettingsReqProps,
  GetSettingsResProps,
  UpdateSettingsReqProps,
  UpdateSettingsResProps
} from './settings.types';

export const settingsApi = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: (build) => ({
    getSettings: build.query<GetSettingsResProps, GetSettingsReqProps>({
      keepUnusedDataFor: 0,
      query: () => 'notifications/settings'
    }),
    updateSettings: build.mutation<UpdateSettingsResProps, UpdateSettingsReqProps>({
      query: (body) => ({
        body,
        method: 'PATCH',
        url: 'notifications/settings'
      })
    }),
    getGlobalSettings: build.query<GetGeneralSettingsRes, void>({
      query: () => '/settings',
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        if (data) {
          dispatch(setGlobalSettings(data));
        }
      }
    })
  }),
  reducerPath: 'settingsApi',
  tagTypes: ['Settings']
});

export const { useGetSettingsQuery, useUpdateSettingsMutation, useGetGlobalSettingsQuery } =
  settingsApi;
