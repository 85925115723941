import React from 'react';
import { Common } from '@thecvlb/design-system';
import dayjs from 'dayjs';

import useWidth from 'hooks/useWidth';
import { DateFormat } from 'utils/enums';

import { DocumentProps } from './document.types';

const Document: React.FC<DocumentProps> = ({
  title,
  date,
  onDownload,
  dataTestId,
  loading,
  onView
}) => {
  const { isMobile } = useWidth();

  const handleClickDownload = () => {
    isMobile && onDownload();
  };

  return (
    <div
      className="flex items-center gap-3 px-2 py-4 md:px-0"
      data-testid={dataTestId}
      onClick={handleClickDownload}
    >
      <Common.Icon
        className="text-primary md:size-[42px] md:rounded-lg md:bg-primary-50 md:p-[9px]"
        name="articles"
      />
      <div className="flex flex-1 flex-col">
        <p className="break-all font-semibold text-gray-700">{title}</p>
        {date && (
          <p
            className="mt-0.5 text-mSm font-medium text-gray md:mt-1 md:text-sm"
            data-testid="uploaded_when"
          >
            {dayjs(date).format(DateFormat.MMM_DD_YYYY)}
          </p>
        )}
      </div>
      {onView ? (
        <Common.Button
          className="hidden md:flex"
          color="green-alt"
          dataTestId="view_btn"
          size="sm"
          onClick={onView}
        >
          View
        </Common.Button>
      ) : (
        <Common.Button
          className="hidden md:flex"
          color="green-alt"
          dataTestId="download_btn"
          isLoading={loading}
          preIcon="download"
          size="sm"
          onClick={onDownload}
        >
          Download
        </Common.Button>
      )}
    </div>
  );
};

export default Document;
