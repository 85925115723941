import { Common } from '@thecvlb/design-system';

import { getIcon } from 'widgets/KrogerMap/krogerMap.settings';

import { parseDecimal, parsePhoneNumber } from 'utils/helpers';

import { ActionsCardProps } from './actionsCard.types';

const ActionsCard: React.FC<ActionsCardProps> = ({ selectedPharmacy, onContinue, distance }) => {
  const { url } = getIcon(selectedPharmacy, false, selectedPharmacy._id);

  return (
    <div className="absolute inset-x-0 bottom-4 z-20 mx-auto flex w-full max-w-[calc(100%_-_32px)] flex-col gap-4 rounded-2xl bg-white p-4 shadow-mXl md:left-[350px] md:max-w-[320px]">
      <div className="flex flex-col gap-1">
        <p className="flex items-center gap-1">
          {<img alt="store-logo" className="size-5" src={url} />}
          <b className="flex-1">{selectedPharmacy.name}</b>
          {distance && <span className="text-gray">{parseDecimal(distance)} mi</span>}
        </p>
        <p>
          {selectedPharmacy.address}, {selectedPharmacy.city}, {selectedPharmacy.state} ,{' '}
          {selectedPharmacy.zipCode}
        </p>
        <p className="text-gray">{parsePhoneNumber(selectedPharmacy.phone)}</p>
      </div>
      <div className="flex gap-4">
        <Common.Button
          className="w-full justify-center !bg-kroger-blue text-white"
          onClick={onContinue}
        >
          Shop lists
        </Common.Button>
      </div>
    </div>
  );
};

export default ActionsCard;
