import { useGetExternalAccountsQuery } from 'services/myAccount/myAccount';

export const useKrogerIDConnected = () => {
  const { data, isFetching } = useGetExternalAccountsQuery({ provider: 'Kroger' });
  const externalAccounts = data?.data?.externalAccounts;

  const isKrogerIDConnected = !!externalAccounts?.some((account) => account.provider === 'Kroger');

  return { isKrogerIDConnected, isFetching };
};

export default useKrogerIDConnected;
