import {
  Dispatch,
  MutableRefObject,
  RefObject,
  SetStateAction,
  useEffect,
  useRef,
  useState
} from 'react';
import { Common } from '@thecvlb/design-system';
import { Chart, ChartType, ChartTypeRegistry, TooltipItem } from 'chart.js';
import classNames from 'classnames';
import dayjs, { ManipulateType } from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

import { WEIGHT_TABS } from 'utils/constants';
import { DateFormat } from 'utils/enums';

import { WeightChartProps } from './weightChart.types';

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const grid = {
  display: false
};
const legend = {
  display: false
};
const ticks = {
  display: false
};
const border = {
  width: 0
};
const interaction = {
  intersect: false
};
const parsing = {
  xAxisKey: 'date',
  yAxisKey: 'value'
};

const clip = false;

const tooltip = {
  bodyFont: { size: 13, weight: 'bold' as const },
  borderWidth: 1,
  callbacks: {
    label: (tooltipItem: TooltipItem<ChartType>) => tooltipItem.formattedValue + ' lbs'
  },
  caretSize: 0,
  displayColors: false,
  intersect: false,
  padding: 8,
  titleFont: { size: 9, weight: 'normal' as const }
};

const pointConfig = {
  borderWidth: 3,
  pointBorderWidth: 0,
  pointHitRadius: 6,
  pointHoverRadius: 6,
  pointRadius: 0,
  tension: 0.3
};

const WeightChart: React.FC<WeightChartProps> = ({
  target,
  current,
  hideTitle = false,
  hideTimeframeTabs = false
}) => {
  const chartCurrentRef = useRef<HTMLCanvasElement>(null);
  const chartTargetRef = useRef<HTMLCanvasElement>(null);
  const chartCurrentId = useRef<string | null>(null);
  const chartTargetId = useRef<string | null>(null);
  const [selectedTab, setSelectedTab] = useState<string>('All');
  const [chartCurrent, setChartCurrent] =
    useState<Chart<keyof ChartTypeRegistry, { date: number; value: number }[], unknown>>();
  const [weightDeltaPercentage, setWeightDeltaPercentage] = useState<number>(0);
  const [chartTarget, setChartTarget] =
    useState<Chart<keyof ChartTypeRegistry, { date: number; value: number }[], unknown>>();
  const labels = [...current, target]
    .sort((a, b) => dayjs(a?.date).diff(b?.date))
    .map((el) => dayjs(el?.date).valueOf());
  const currentData = current.map((el) => ({
    date: dayjs(el.date).valueOf(),
    value: el.value
  }));
  const targetData = [
    ...(current.length
      ? [
          {
            date: dayjs(current[0].date).valueOf(),
            value: current[0].value
          }
        ]
      : []),
    ...(target
      ? [
          {
            date: dayjs(target?.date).valueOf(),
            value: target?.value
          }
        ]
      : [])
  ];

  const getRangeFormat = (minX?: number, maxX?: number) => {
    let isRangeWide: boolean;
    if (!minX || !maxX) {
      isRangeWide = dayjs(labels[labels.length - 1]).diff(labels[0], 'days') > 180;
    } else {
      isRangeWide = dayjs(maxX).diff(minX, 'days') > 180;
    }

    return isRangeWide ? DateFormat.MMM_YY : DateFormat.MMM_D;
  };

  const getChartData = () => {
    let constraintDuration: ManipulateType | undefined = undefined;
    let minX = undefined;
    let maxX = undefined;

    switch (selectedTab) {
      case 'Week':
        constraintDuration = 'week';
        break;
      case 'Month':
        constraintDuration = 'month';
        break;
      case 'Year':
        constraintDuration = 'year';
        break;
    }

    if (!constraintDuration || !current.length) {
      return { labels, maxX, minX, targetData };
    }

    const firstMeasurementDate = current[0].date;
    const lastMeasurementDate = current[current.length - 1].date;

    if (dayjs(lastMeasurementDate).subtract(1, constraintDuration).isBefore(firstMeasurementDate)) {
      minX = dayjs(firstMeasurementDate).valueOf();
      maxX = dayjs(firstMeasurementDate).add(1, constraintDuration).valueOf();
    } else {
      minX = dayjs(lastMeasurementDate).subtract(1, constraintDuration).valueOf();
      maxX = dayjs(lastMeasurementDate).valueOf();
    }
    return { maxX, minX };
  };

  const generateChart = (
    type: 'current' | 'target',
    chartRef: RefObject<HTMLCanvasElement>,
    chartId: MutableRefObject<string | null>,
    data: { date: number; value: number }[],
    datasetIndex: number,
    cb: Dispatch<
      SetStateAction<
        Chart<keyof ChartTypeRegistry, { date: number; value: number }[], unknown> | undefined
      >
    >
  ) => {
    if (chartRef.current === null || chartId.current !== null) {
      return;
    }
    const ctx = chartRef.current.getContext('2d');
    const gradientStroke = ctx?.createLinearGradient(350, 0, 1, 0);
    gradientStroke?.addColorStop(0, '#00698B');
    gradientStroke?.addColorStop(1, '#19D1CB');
    let isTooltip = false;
    const chart = new Chart(chartRef.current, {
      data: {
        datasets: [
          {
            ...pointConfig,
            borderColor: gradientStroke,
            data: currentData,
            pointBackgroundColor: gradientStroke,
            showLine: type !== 'current'
          },
          {
            data: targetData,
            ...(type === 'current'
              ? { pointHoverRadius: 0, pointRadius: 0, showLine: false }
              : {
                  ...pointConfig,
                  borderCapStyle: 'round',
                  borderColor: '#C4CACC',
                  borderDash: [10, 9],
                  pointBackgroundColor: '#C4CACC',
                  pointBorderColor: '#C4CACC',
                  pointHoverBackgroundColor: '#00B167',
                  pointHoverBorderColor: '#00B167'
                })
          }
        ]
      },
      options: {
        animation: {
          onComplete: () => {
            if (!isTooltip) {
              const segment = chart.getActiveElements()[0];
              if (!segment?.element) {
                return;
              }
              chart.tooltip?.setActiveElements([segment], {
                x: segment.element.x,
                y: segment.element.y
              });
              chart.update();
              isTooltip = true;
            }
          }
        },
        clip,
        interaction,
        parsing,
        plugins: {
          legend,
          tooltip: {
            ...tooltip,
            ...(type === 'current'
              ? {
                  backgroundColor: '#388FAB',
                  bodyColor: '#ffffff',
                  callbacks: {
                    ...tooltip.callbacks,
                    title: (tooltipItems: TooltipItem<ChartType>[]) => {
                      if (tooltipItems.length === 0) {
                        return undefined;
                      }
                      if (tooltipItems[0].dataIndex === currentData.length - 1) {
                        return 'Current';
                      }
                      if (currentData[tooltipItems[0].dataIndex]) {
                        return dayjs
                          .utc(currentData[tooltipItems[0].dataIndex].date)
                          .format(
                            dayjs.utc().diff(currentData[tooltipItems[0].dataIndex].date, 'year')
                              ? DateFormat.MMM_D
                              : DateFormat.MMM_DD_YYYY
                          );
                      }
                    }
                  },
                  filter: (tooltipItem) => tooltipItem.datasetIndex === 0,
                  titleColor: '#ffffff'
                }
              : {
                  backgroundColor: '#ffffff',
                  bodyColor: '#424647',
                  borderColor: '#E2E7E9',
                  callbacks: {
                    ...tooltip.callbacks,
                    title: () => 'Target'
                  },
                  titleColor: '#424647'
                }),
            yAlign: 'bottom'
          }
        },
        maintainAspectRatio: false,
        scales: {
          x: {
            border,
            grid,
            ticks:
              type === 'current'
                ? {
                    callback: () => undefined,
                    color: '#424647',
                    font: { weight: 'bold' }
                  }
                : {
                    color: 'transparent'
                  },
            type: 'time'
          },
          y: {
            border,
            grid:
              type === 'current'
                ? grid
                : {
                    color: '#F4F7F8'
                  },
            ticks
          }
        },
        ...(type === 'current' && {
          events: ['mousemove']
        }),
        layout: {
          autoPadding: false
        }
      },
      type: 'line'
    });
    data.length && chart.setActiveElements([{ datasetIndex, index: data.length - 1 }]);
    chartId.current = chart.id;
    cb(chart);
  };

  const handleSetWeightDeltaPercentage = (maxX?: number, minX?: number) => {
    if (!currentData.length || !chartCurrent) {
      return;
    }
    const firstWeight = currentData[0];
    const lastWeight = currentData[currentData.length - 1];
    if (minX) {
      const startElementIndex = currentData.findIndex((el) => minX < el.date);
      const startElement = currentData[startElementIndex - 1];
      if (startElement) {
        const endElement = currentData[startElementIndex];

        const start = { x: startElement.date, y: startElement.value };
        const end = { x: endElement.date, y: endElement.value };

        const slope = (end.y - start.y) / (end.x - start.x);
        const yIntercept = start.y - slope * start.x;

        firstWeight.value = slope * minX + yIntercept;
      }
    }

    const wdp = Math.round(((firstWeight.value - lastWeight.value) / firstWeight.value) * 100);
    setWeightDeltaPercentage(wdp);
  };

  const updateCurrentChart = (maxX?: number, minX?: number) => {
    if (!chartCurrent || !chartCurrent.options.scales?.x || !chartCurrent.options.plugins) {
      return;
    }

    const rangeFormat: DateFormat = getRangeFormat(minX, maxX);

    chartCurrent.data.datasets[0].data = currentData;
    chartCurrent.data.datasets[1].data = targetData;
    chartCurrent.options.scales.x.max = maxX;
    chartCurrent.options.scales.x.min = minX;
    chartCurrent.options.scales.x = {
      ...chartCurrent.options.scales.x,
      ticks: {
        ...chartCurrent.options.scales.x.ticks,

        callback: (value, index, ticks) => {
          if (index === 0) {
            return dayjs.utc(minX || labels[0]).format(rangeFormat);
          } else if (index === ticks.length - 1) {
            return dayjs.utc(maxX || labels[labels.length - 1]).format(rangeFormat);
          } else {
            return undefined;
          }
        }
      }
    };
    chartCurrent.update();
    handleSetWeightDeltaPercentage(maxX, minX);
  };

  const updateTargetChart = (maxX?: number, minX?: number) => {
    if (!chartTarget || !chartTarget.options.scales?.x || !chartTarget.options.plugins) {
      return;
    }

    const rangeFormat: DateFormat = getRangeFormat(minX, maxX);

    chartTarget.data.datasets[0].data = currentData;
    chartTarget.data.datasets[1].data = targetData;
    chartTarget.options.scales.x.max = maxX;
    chartTarget.options.scales.x.min = minX;
    chartTarget.options.scales.x = {
      ...chartTarget.options.scales.x,
      ticks: {
        ...chartTarget.options.scales.x.ticks,
        callback: (value, index, ticks) => {
          if (index === 0) {
            return dayjs(minX || labels[index]).format(rangeFormat);
          } else if (index === ticks.length - 1) {
            return dayjs(maxX || labels[labels.length - 1]).format(rangeFormat);
          } else {
            return undefined;
          }
        }
      }
    };
    chartTarget.options.plugins.tooltip = {
      ...chartTarget.options.plugins.tooltip,
      enabled:
        !minX ||
        (dayjs(target?.date).isSameOrAfter(minX) && dayjs(target?.date).isSameOrBefore(maxX))
    };
    chartTarget.update();
  };

  useEffect(() => {
    const { maxX, minX } = getChartData();
    if (chartCurrent) {
      updateCurrentChart(maxX, minX);
    } else {
      generateChart('current', chartCurrentRef, chartCurrentId, currentData, 0, setChartCurrent);
    }
    if (chartTarget) {
      updateTargetChart(maxX, minX);
    } else {
      generateChart('target', chartTargetRef, chartTargetId, targetData, 1, setChartTarget);
    }
  }, [currentData]);

  return (
    <div className="flex flex-col gap-4">
      <div>
        {!hideTitle && <h2 className="text-mBase font-bold">Weight</h2>}
        {currentData.length > 1 && (
          <p className="mt-2 flex gap-1 text-mSm font-semibold text-gray">
            <span
              className={classNames(
                'flex font-bold text-green',
                weightDeltaPercentage < 0 ? 'text-red' : 'text-green'
              )}
            >
              <Common.Icon name={weightDeltaPercentage < 0 ? 'arrow-up' : 'arrow-down'} />
              {Math.abs(weightDeltaPercentage)}%
            </span>
            {selectedTab === 'All' ? 'from start' : `from last ${selectedTab.toLowerCase()}`}
          </p>
        )}
      </div>
      <div className="mx-auto w-full">
        <canvas className="absolute" ref={chartCurrentRef} />
        <canvas ref={chartTargetRef} />
      </div>
      {!hideTimeframeTabs && (
        <Common.Tabs
          className="w-full justify-between rounded-full bg-white p-1 shadow"
          data={WEIGHT_TABS}
          defaultSelected={[selectedTab]}
          type="bar"
          isDesktop
          onChange={(el) => setSelectedTab(el.label)}
        />
      )}
    </div>
  );
};

export default WeightChart;
