import { useState } from 'react';
import { useTitle, useToggle } from 'react-use';
import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';
import { nanoid } from 'nanoid';

import UpdateBirthday from 'modals/UpdateBirthday';
import VerifyYourIdentity from 'modals/VerifyYourIdentity';
import FadeWrapper from 'shared/animationWrappers/FadeWrapper';
import Loader from 'shared/Loader';

import { useGetValidationDocs } from 'hooks/useGetValidationDocs';

// import { ERRORS_FOR_NOTIFICATIONS_FROM_VOUCHED } from 'utils/constants';
import { Props } from './intro.types';

const Intro: React.FC<Props> = ({ onContinue, onSkip, inPopup = false, loading = false }) => {
  useTitle('Verify your identity', { restoreOnUnmount: true });
  const {
    docs: { government, personPhoto },
    isLoading: isGettingDocuments,
    refetchDocuments,
    handleVerifyIdentity
  } = useGetValidationDocs();
  const showSkipButton = !government.identity?.isVerified || !personPhoto.identity?.isVerified;
  const governmentErrors = government?.identity?.errors || [];
  const personPhotoErrors = personPhoto?.identity?.errors || [];
  const mergedErrors = governmentErrors.concat(personPhotoErrors).reduce(
    (acc, current) => {
      const isDuplicate = acc.some((error) => error.message === current.message);
      return isDuplicate ? acc : [...acc, current];
    },
    [] as { message: string; type: string }[]
  );
  const [verifyYourIdentityType, setVerifyYourIdentityType] = useState<
    'SELFIE' | 'GV' | undefined
  >();

  const [isOpenUpdateBirthday, toggleIsOpenUpdateBirthday] = useToggle(false);

  const handleContinue = () => {
    if (!government._id) {
      setVerifyYourIdentityType('GV');
    } else if (!personPhoto._id) {
      setVerifyYourIdentityType('SELFIE');
    } else {
      handleVerifyIdentity(government._id, personPhoto._id, (isVerified) => {
        if (!!isVerified) {
          onContinue();
        } else {
          refetchDocuments();
        }
      });
    }
  };

  const handleOnCloseUpdateBirthday = (triggerVouched?: boolean) => {
    if (triggerVouched && government._id) {
      handleVerifyIdentity(government._id);
    }
    toggleIsOpenUpdateBirthday();
  };

  const isVerifiedFailed = sessionStorage.getItem('identity-verified') === 'false';

  const card = (type: 'SELFIE' | 'GV') => {
    const identity = type === 'GV' ? government.identity : personPhoto.identity;
    const status = identity ? (identity?.isVerified ? 'success' : 'error') : undefined;
    const isDisabled = type === 'SELFIE' && !government._id;
    const filePath =
      (type === 'GV' && government.filePath) || (type === 'SELFIE' && personPhoto.filePath);

    return (
      <div
        className={classNames(
          'relative flex flex-1 flex-col items-center rounded-xl bg-white py-3 shadow',
          isDisabled ? 'cursor-not-allowed' : 'cursor-pointer'
        )}
        onClick={() => !isDisabled && setVerifyYourIdentityType(type)}
      >
        {status && (
          <div className="absolute right-2 top-2 md:right-[88px]">
            {status === 'error' ? (
              <div className="flex size-4 items-center justify-center rounded-full bg-red text-2xs text-white">
                {identity?.errors?.length}
              </div>
            ) : (
              <Common.Icon className="text-green" name="check-circle" />
            )}
          </div>
        )}
        <Common.Icon
          className="size-12 text-primary-400"
          name={type === 'GV' ? 'identification' : 'person-capture-photo'}
        />
        <p className="mb-4 font-semibold">{type === 'GV' ? 'Government ID' : 'Selfie photo'}</p>
        <Common.Button color="white" disabled={isDisabled} size="sm">
          {filePath ? 'View' : 'Upload'}
        </Common.Button>
      </div>
    );
  };

  return (
    <FadeWrapper
      className={classNames('h-full md:rounded-2xl md:bg-white md:p-8', { 'md:shadow': !inPopup })}
    >
      <UpdateBirthday isOpen={isOpenUpdateBirthday} onClose={handleOnCloseUpdateBirthday} />
      <Loader isVisible={isGettingDocuments} />
      <VerifyYourIdentity
        docs={{ government, personPhoto }}
        refetchDocuments={refetchDocuments}
        type={verifyYourIdentityType}
        onClose={() => setVerifyYourIdentityType(undefined)}
      />
      <div className="mx-auto flex h-full max-w-[500px] flex-col items-center md:gap-4">
        <Common.Icon
          className="size-20 rounded-full bg-primary-50 p-4 text-primary-400 max-md:hidden"
          name="tag-person"
        />
        <h2 className="text-m2xl font-bold text-primary-700 max-md:self-start md:text-2xl">
          Verify your identity
        </h2>

        {isVerifiedFailed && showSkipButton ? (
          <Common.Alert type="error" colorableBackground>
            <span className="text-mSm text-gray-600 md:text-sm">
              <b>We had trouble verifying your identity.</b> Please fix the errors on the following
              pages in order to complete your onboarding.
            </span>
          </Common.Alert>
        ) : (
          <>
            <Common.Alert className="max-md:hidden" type="plain" colorableBackground>
              <span className="text-mSm text-gray-600 md:text-sm">
                To comply with regulations, we need to confirm your identity. We will never share
                this information.
              </span>
            </Common.Alert>
            <p className="text-mSm text-gray-600 md:hidden">
              To comply with regulations, we need to confirm your identity. We will never share this
              information.
            </p>
          </>
        )}

        <div className="flex w-full gap-3 max-md:my-6">
          {card('GV')}
          {card('SELFIE')}
        </div>
        {!isVerifiedFailed &&
          mergedErrors.map((error) => (
            <Common.Alert
              className="w-full max-md:mb-6"
              key={nanoid()}
              type="error"
              colorableBackground
            >
              <b>Error:</b> {error.message}
              {error.type === 'BirthDateMatchError' && (
                <Common.Button
                  className="mt-2"
                  color="white-alt"
                  onClick={toggleIsOpenUpdateBirthday}
                >
                  Update birthdate
                </Common.Button>
              )}
            </Common.Alert>
          ))}
        <div className="mt-auto flex w-full flex-col items-center gap-4 md:mt-4 md:gap-2">
          <Common.Button color="blue" disabled={loading} fullWidthOnMobile onClick={handleContinue}>
            Continue
          </Common.Button>
          {showSkipButton && (
            <Common.Button disabled={loading} fullWidthOnMobile onClick={onSkip}>
              I’ll do this later
            </Common.Button>
          )}
        </div>
      </div>
    </FadeWrapper>
  );
};

export default Intro;
