import classNames from 'classnames';

import RangeSection from '../RangeSection';

import { getRangeSections } from './rangeScale.settings';
import { RangeScaleProps } from './rangeScale.types';

const RangeScale: React.FC<RangeScaleProps> = ({ referenceMax, referenceMin, value }) => {
  const rangeSections = getRangeSections(value, referenceMax, referenceMin);

  const wrapperClasses = classNames(
    'grid gap-px',
    referenceMax && referenceMin ? 'grid-cols-3' : 'grid-cols-2'
  );
  return (
    <div className={wrapperClasses}>
      {rangeSections?.map((rangeSectionsProps) => (
        <RangeSection key={rangeSectionsProps.label} {...rangeSectionsProps} />
      ))}
    </div>
  );
};

export default RangeScale;
