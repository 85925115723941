import { useEffect, useState } from 'react';

const Marker: React.FC<google.maps.MarkerOptions & { onClick: () => void }> = (options) => {
  const [marker, setMarker] = useState<google.maps.Marker>();

  useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.Marker({}));
    }

    marker?.addListener('click', options.onClick);

    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  useEffect(() => {
    if (marker) {
      marker.setOptions(options);
    }
  }, [marker, options]);

  return null;
};

export default Marker;
