import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';

import { getIcon } from 'widgets/KrogerMap/krogerMap.settings';

import { parseDecimal, parsePhoneNumber } from 'utils/helpers';

import { ItemCardProps } from './itemCard.types';

const ItemCard: React.FC<ItemCardProps> = ({
  shop,
  onClick,
  isSelected = false,
  isTransparent = false,
  distance
}) => {
  const handelClick = (e: React.MouseEvent) => {
    e.preventDefault();
    onClick(shop._id, 'list');
  };

  const { url } = getIcon(shop, false, shop._id);

  return (
    <button
      className={classNames('flex w-full items-center gap-1 p-4 text-left', {
        'bg-gray-50': isSelected && !isTransparent
      })}
      onClick={handelClick}
    >
      <div className="flex flex-1 flex-col gap-1">
        <p className="flex w-full items-center gap-1">
          {<img alt="store-logo" className="size-5" src={url} />}
          <b className="flex-1">{shop.name}</b>
          {distance && <span className="text-gray">{parseDecimal(distance)} mi</span>}
        </p>
        <p>
          {shop.address}, {shop.city}, {shop.state} {shop.zipCode}
        </p>
        <p className="text-gray">{parsePhoneNumber(shop.phone)}</p>
      </div>
      <Common.Icon className="flex-none text-gray" name="arrow-right" />
    </button>
  );
};

export default ItemCard;
