import { useState } from 'react';
import { useToggle } from 'react-use';
import { Common } from '@thecvlb/design-system/lib/src';

import ShopOrderDetails from 'modals/ShopOrderDetails';

import { TrackerProps } from './tracker.types';

const Tracker: React.FC<TrackerProps> = ({ orders }) => {
  const [isOpenShopOrderDetails, toggleIsOpenShopOrderDetails] = useToggle(false);
  const [selectedOrder, setSelectedOrder] = useState<number>(0);

  const handleOpenOrder = (index: number) => {
    setSelectedOrder(index);
    toggleIsOpenShopOrderDetails();
  };

  const isMultipleOrders = orders?.length > 1;
  const heading = `Your ${isMultipleOrders ? 'products are' : `${orders?.[0].items[0]?.product?.name} is`} on their way!`;
  const status = isMultipleOrders ? 'Ordered' : 'We will update when it has been shipped';
  return (
    <>
      <ShopOrderDetails
        data={orders[selectedOrder]}
        isOpen={isOpenShopOrderDetails}
        onClose={toggleIsOpenShopOrderDetails}
      />
      <div className="flex flex-col gap-4 rounded-2xl bg-secondary-100 p-4">
        <div className="text-primary-700">
          <h2 className="font-bold">{heading}</h2>
          <span className="mt-1 text-mSm">{status}</span>
        </div>
        {!!orders?.length &&
          orders.map((order, idx) => {
            const isLabKit = order.items[0].product?.type?.toLowerCase().includes('lab kit');
            return (
              <button
                className="flex items-center gap-4 rounded-2xl border border-black/10 bg-white p-2 shadow-sm max-md:px-4"
                onClick={() => handleOpenOrder(idx)}
              >
                {!isLabKit && (
                  <div className="text-start">
                    <h4 className="font-bold text-gray-950">Order #: {order.orderNumber}</h4>
                    {order.trackingNumber && <span>Tracking: {order.trackingNumber}</span>}
                  </div>
                )}
                {isLabKit && (
                  <div className="flex" data-testid="product_item">
                    <img
                      alt={order.items[0].product.name}
                      className="mx-auto h-[63px] max-w-full object-contain transition-all group-hover:scale-[102%]"
                      data-testid="product_img"
                      src={order.items[0].product?.featuredAsset?.url}
                    />
                    <div className="ml-4 flex flex-col justify-center text-left">
                      <div className="text-mBase font-bold">{order?.items?.[0].product.name}</div>
                      {order.trackingNumber ? (
                        <span>Tracking: {order.trackingNumber}</span>
                      ) : (
                        <span className="text-mSm text-gray-500">Learn more</span>
                      )}
                    </div>
                  </div>
                )}
                <Common.Icon className="ml-auto size-5 flex-none text-gray" name="arrow-right" />
              </button>
            );
          })}
      </div>
    </>
  );
};

export default Tracker;
