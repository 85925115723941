import { ShopItem } from 'services/kroger/kroger.types';

import Bakers from 'assets/icons/kroger-shops/Store=Baker’s.svg';
import CityMarket from 'assets/icons/kroger-shops/Store=City Market.svg';
import Dillons from 'assets/icons/kroger-shops/Store=Dillons.svg';
import Food4Less from 'assets/icons/kroger-shops/Store=Food 4 Less.svg';
import FoodsCo from 'assets/icons/kroger-shops/Store=Foods Co.svg';
import FredMeyer from 'assets/icons/kroger-shops/Store=Fred Meyer.svg';
import Fry from "assets/icons/kroger-shops/Store=Fry's.svg";
import Gerbes from 'assets/icons/kroger-shops/Store=Gerbes.svg';
import HarrisTeeter from 'assets/icons/kroger-shops/Store=Harris Teeter.svg';
import JayC from 'assets/icons/kroger-shops/Store=Jay C Food Store.svg';
import Vitacost from 'assets/icons/kroger-shops/Store=Jay C Food Store.svg';
import KingSoopers from 'assets/icons/kroger-shops/Store=King Soopers.svg';
import Kroger from 'assets/icons/kroger-shops/Store=Kroger.svg';
import Marianos from "assets/icons/kroger-shops/Store=Mariano's.svg";
import MetroMarket from 'assets/icons/kroger-shops/Store=Metro Market.svg';
import PayLess from 'assets/icons/kroger-shops/Store=Pay-Less Super Markets.svg';
import PickNSave from "assets/icons/kroger-shops/Store=Pick'n Save.svg";
import Qfc from 'assets/icons/kroger-shops/Store=QFC.svg';
import Ralphs from 'assets/icons/kroger-shops/Store=Ralphs.svg';
import Ruler from 'assets/icons/kroger-shops/Store=Ruler.svg';
import Smiths from "assets/icons/kroger-shops/Store=Smith's Food and Drug.svg";

export const getIcon = (shop: ShopItem, isOpen: boolean, _id?: string, isMobile?: boolean) => {
  let size: number;
  if (_id === shop._id && isOpen) {
    size = isMobile ? 48 : 38;
  } else {
    size = isMobile ? 32 : 28;
  }
  return {
    scaledSize: new google.maps.Size(size, size),
    size: new google.maps.Size(size, size),
    url: getIconByBrand(shop.brand)
  };
};

const getIconByBrand = (brand: string): string => {
  return brandsMap[brand as keyof typeof brandsMap] || Kroger;
};

const brandsMap = {
  fred_meyer: FredMeyer,
  frys: Fry,
  ralphs: Ralphs,
  king_soopers: KingSoopers,
  city_market: CityMarket,
  kroger: Kroger,
  qfc: Qfc,
  harris_teeter: HarrisTeeter,
  marianos: Marianos,
  payless: PayLess,
  jay_c: JayC,
  dillon: Dillons,
  gerbes: Gerbes,
  bakers: Bakers,
  metro_market: MetroMarket,
  pick_n_save: PickNSave,
  smiths: Smiths,
  vitacost: Vitacost,
  ruler: Ruler,
  food_4_less: Food4Less,
  foods_co: FoodsCo
} as const;
