import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQueryWithReauth } from 'utils/services';

import {
  ConnectKrogerIDReq,
  GetKrogerRecipesListRes,
  GetKrogerStoresReq,
  GetKrogerStoresRes
} from './kroger.types';

export const krogerApi = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: (build) => ({
    getKrogerRecipes: build.query<GetKrogerRecipesListRes, void>({
      keepUnusedDataFor: 0,
      query: () => ({
        url: '/kroger/recipes'
      })
    }),
    connectKrogerID: build.mutation<unknown, ConnectKrogerIDReq>({
      query: (body) => ({
        method: 'POST',
        url: '/external-account/kroger',
        body
      })
    }),
    getKrogerShops: build.query<GetKrogerStoresRes, GetKrogerStoresReq>({
      keepUnusedDataFor: 0,
      query: (params) => ({
        params,
        url: 'kroger-stores/search'
      })
    })
  }),

  reducerPath: 'krogerApi',
  tagTypes: ['kroger']
});

export const { useGetKrogerRecipesQuery, useLazyGetKrogerShopsQuery, useConnectKrogerIDMutation } =
  krogerApi;
