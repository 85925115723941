import { useToggle } from 'react-use';
import { Common } from '@thecvlb/design-system';

import { parsePhoneNumber } from 'utils/helpers';

import { PharmacyActionsCardProps } from './pharmacyActionsCard.types';

import PointOpenSvg from 'assets/images/map/point-open.svg?react';

const PharmacyActionsCard: React.FC<PharmacyActionsCardProps> = ({
  onDeselect,
  selectedPharmacy,
  onChoose,
  onContinue,
  userPharmacy,
  distance,
  isLoading
}) => {
  const [isDeselect, toggleDeselect] = useToggle(false);
  const handleContinue = () => {
    onContinue?.();
    toggleDeselect();
  };

  return (
    <div className="absolute inset-x-0 bottom-4 z-20 mx-auto flex w-full max-w-[calc(100%_-_32px)] flex-col gap-4 rounded-2xl bg-white p-4 shadow-mXl md:left-[350px] md:max-w-[320px]">
      <div className="flex flex-col gap-1">
        <p className="flex items-center gap-1">
          <PointOpenSvg className="size-5" />
          <b className="flex-1">{selectedPharmacy.name}</b>
          {distance && <span className="text-gray">{distance} mi</span>}
        </p>
        <p>
          {selectedPharmacy.address}, {selectedPharmacy.city}, {selectedPharmacy.state}{' '}
          {selectedPharmacy.zipCode}
        </p>
        <p className="text-gray">{parsePhoneNumber(selectedPharmacy.phone)}</p>
      </div>
      <div className="flex gap-4">
        {userPharmacy?.pharmacyId === selectedPharmacy.pharmacyId && !isLoading ? (
          <>
            <Common.Button
              className="pointer-events-none w-full justify-center text-green"
              preIcon="check"
              style="text-only"
            >
              My pharmacy
            </Common.Button>
            {isDeselect ? (
              <Common.Button
                className="w-full justify-center"
                color="white-alt"
                disabled={isLoading}
                isLoading={isLoading}
                onClick={onDeselect}
              >
                Deselect
              </Common.Button>
            ) : (
              <Common.Button
                className="w-full justify-center"
                color="blue"
                onClick={handleContinue}
              >
                Continue
              </Common.Button>
            )}
          </>
        ) : (
          <Common.Button
            className="w-full justify-center"
            color="blue"
            disabled={isLoading}
            isLoading={isLoading}
            onClick={onChoose}
          >
            Choose pharmacy
          </Common.Button>
        )}
      </div>
    </div>
  );
};

export default PharmacyActionsCard;
