import React from 'react';

import useWidth from 'hooks/useWidth';

const CircleChart: React.FC<{
  inRangeCount: number;
  outOfRangeCount: number;
  totalCount: number;
}> = ({ inRangeCount, outOfRangeCount, totalCount }) => {
  const isMobile = useWidth();
  const inRangePercentage = (inRangeCount / totalCount) * 100;
  const outOfRangePercentage = (outOfRangeCount / totalCount) * 100;

  const size = isMobile ? 130 : 82;
  const radius = (size - 10) / 2; // Subtracting stroke width for proper fit
  const strokeWidth = 10; // Thickness of the border
  const circumference = 2 * Math.PI * radius;

  const gap = 2; // Gap size in pixels
  const gapLength = (gap / circumference) * 100;

  const inRangeStroke = (inRangePercentage / 100) * circumference;
  const outOfRangeStroke = (outOfRangePercentage / 100) * circumference;

  return (
    <div className="flex items-center justify-center">
      <svg
        height={size}
        viewBox={`0 0 ${size} ${size}`}
        width={size}
        xmlns="http://www.w3.org/2000/svg"
      >
        {/* Background circle */}
        <circle
          cx={size / 2}
          cy={size / 2}
          fill="none"
          r={radius}
          stroke="#e5e7eb" // `gray-300`
          strokeWidth={strokeWidth}
        />

        {/* Blue segment */}
        <circle
          cx={size / 2}
          cy={size / 2}
          fill="none"
          r={radius}
          stroke="#7cbace" // `blue-300`
          strokeDasharray={`${inRangeStroke - gapLength} ${circumference - inRangeStroke + gapLength}`}
          strokeDashoffset="0"
          strokeWidth={strokeWidth}
          transform={`rotate(-90 ${size / 2} ${size / 2})`}
        />

        {/* Yellow segment */}
        <circle
          cx={size / 2}
          cy={size / 2}
          fill="none"
          r={radius}
          stroke="#ffa700" // `yellow-500`
          strokeDasharray={`${outOfRangeStroke - gapLength} ${circumference - outOfRangeStroke + gapLength}`}
          strokeDashoffset={`-${inRangeStroke}`}
          strokeWidth={strokeWidth}
          transform={`rotate(-90 ${size / 2} ${size / 2})`}
        />
      </svg>
    </div>
  );
};

export default CircleChart;
