import { useEffect, useState } from 'react';
import { Common } from '@thecvlb/design-system';
import dayjs from 'dayjs';

import Document from 'widgets/myChart/Documents/Document';

import { DateFormat } from 'utils/enums';

import { EditDocumentProps } from './editDocument.types';

const EditDocument: React.FC<EditDocumentProps> = ({
  isOpen,
  onClose,
  onConfirm,
  document,
  isLoading
}) => {
  const [description, setDescription] = useState('');

  useEffect(() => {
    setDescription(document?.description || '');
  }, [document]);

  return (
    <Common.Modal close={onClose} isOpen={isOpen} padding={false} size="sm">
      <div className="flex flex-col gap-6 p-4">
        <div>
          <h3 className="mb-2 truncate text-xl font-bold">Edit details: "{document?.fileName}"</h3>
          <p className="text-base font-medium">
            {dayjs(document?.createdAt).format(DateFormat.MMMM_DD_YYYY)} •{' '}
            {document?.isUploadedByCurrentUser ? 'Me' : document?.uploadedBy}
          </p>
        </div>
        {document && <Document document={document} />}
        <Common.TextArea
          label="Details"
          placeholder="What would you like us to know?"
          rows={6}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <div className="flex flex-col-reverse gap-3 md:flex-row">
          <Common.Button
            className="grow"
            color="white-alt"
            dataTestId="cancel_btn"
            disabled={isLoading}
            fullWidthOnMobile
            onClick={onClose}
          >
            Cancel
          </Common.Button>
          <Common.Button
            className="grow"
            color="blue"
            dataTestId="save_on_popup_btn"
            disabled={isLoading}
            fullWidthOnMobile
            onClick={() => onConfirm(description)}
          >
            Save
          </Common.Button>
        </div>
      </div>
    </Common.Modal>
  );
};

export default EditDocument;
