import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTitle } from 'react-use';

import { useLazyGetMifStructureQuery, useSendMifResponseMutation } from 'services/mifs/mifs';
import {
  AppointmentMifQuestion,
  MifStructure,
  QuestionWithAnswerConfig,
  SubmitMifResponseReq
} from 'services/mifs/mifs.types';

import { selectAppointments, selectMifInfo, store } from 'store';
import { clearAppointmentMif } from 'store/mif/mifSlice';

import { useAppDispatch, useAppSelector, useQuery } from 'hooks';
import { PathName } from 'utils/enums';
import { handleRequestCatch } from 'utils/helpers';

import { useMifNavigate } from './useMifNavigate';

const getSlug = (config?: AppointmentMifQuestion['config']) => {
  if (!config) {
    return 'intro';
  }
  if (config.type === 'info') {
    return config.group;
  }
  return config.question.value;
};

export const useAppointmentMif = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  useTitle('Medical Intake Form');
  const { isEdit } = useMifNavigate();

  const { mifID = '' } = useParams();
  const stepFromQuery = useQuery().get('s') ?? '';
  const redirectStep = useQuery().get('redirectStep') ?? 'date-time';

  const { appointmentMif = [] } = useAppSelector(selectMifInfo);
  const { newAppointment } = useAppSelector(selectAppointments);

  const [getStructure, { data, isUninitialized, isLoading: initialLoading }] =
    useLazyGetMifStructureQuery();
  const [submitMif, { isLoading: isSubmittingResults }] = useSendMifResponseMutation();

  const [mifSteps, setMifSteps] = useState<MifStructure['configs']>([]);

  const getStepNames = (): string[] => {
    const stepNames = [
      'intro',
      ...mifSteps.map(({ config }) => String(getSlug(config))),
      'results'
    ];
    return stepNames;
  };

  const handleSubmit = () => {
    const body = appointmentMif.map(({ question, answer, textAreaFields }) => ({
      question,
      answer,
      ...(textAreaFields && { textAreaFields })
    }));

    submitMif({ id: mifID, body })
      .unwrap()
      .then(() => {
        // dispatch(clearAppointmentMif());
        const redirectStep = sessionStorage.getItem('redirectStep') ?? 'date-time';
        navigate(PathName.CreateAppointment + `?s=${redirectStep}`);
      });
  };

  const onLeaveFlow = () => {
    dispatch(clearAppointmentMif());
    navigate(PathName.Dashboard, { replace: true });
  };

  const getCurrentQuestionIndex = (): number => {
    const curStep = mifSteps.find(
      (s) => 'question' in s.config && s.config.question.value === stepFromQuery
    );
    const parentStep = mifSteps.find(
      (s) =>
        !!curStep &&
        'question' in s.config &&
        s.config.question.value === (curStep?.config as QuestionWithAnswerConfig)?.showIf?.question
    );
    const filtered = mifSteps.filter(({ config }) => config.type !== 'info' && !config.showIf);
    if (parentStep) {
      return filtered.findIndex((s) => s.order === parentStep.order) + 1;
    }

    return (
      filtered.findIndex(
        (s) => 'question' in s.config && s.config.question.value === stepFromQuery
      ) + 1
    );
  };

  const getActiveStep = () => {
    if (['intro', 'results'].includes(stepFromQuery)) {
      return stepFromQuery;
    }
    return mifSteps.find(({ config }) => getSlug(config) === stepFromQuery);
  };

  const activeStep = getActiveStep();

  const progress = (): number => {
    if (!activeStep) {
      return 0;
    }
    return (
      getStepNames().findIndex(
        (step) =>
          step ===
          (typeof activeStep === 'string' ? activeStep : String(getSlug(activeStep.config)))
      ) ?? 0
    );
  };

  const moveToStep = (step: 'next' | 'prev' | (string & {}), search?: string) => {
    const buildSearch = (originalSearch: string) => originalSearch + (search ? `&${search}` : '');

    if (step === 'next') {
      const reactiveMifState = store.getState().mif.appointmentMif as SubmitMifResponseReq;
      const nextStepName = getStepNames()[progress() + 1];
      const nextStep = mifSteps.find(({ config }) => getSlug(config) === nextStepName);
      if (nextStep?.config.type === 'info' || !nextStep?.config.showIf) {
        return navigate({ search: buildSearch(`s=${isEdit ? 'results' : nextStepName}`) });
      } else {
        // cut previous steps
        const stepsLeft = [...mifSteps].slice(progress());
        // logic to see what conditional step to show
        const nextStepToShow = stepsLeft.find((s) => {
          if (s.config.type === 'info' || !s.config.showIf) {
            return true;
          }
          const parentQuestion = mifSteps.find(
            (s) =>
              'question' in s.config &&
              'showIf' in nextStep.config &&
              s.config.question.value === nextStep?.config.showIf?.question
          );
          const answerOnParentQuestion = reactiveMifState.find(
            (item) =>
              !!parentQuestion &&
              'question' in parentQuestion.config &&
              item.question === parentQuestion?.config.question.value
          )?.answer;
          if (Array.isArray(answerOnParentQuestion)) {
            return answerOnParentQuestion.includes(s.config.showIf.value);
          }
          return s.config.showIf.value === answerOnParentQuestion;
        });
        const lastStep = mifSteps.at(-1);

        if (isEdit) {
          if (
            typeof activeStep === 'object' &&
            'order' in activeStep &&
            activeStep.order !== Math.floor(Number(nextStepToShow?.order))
          ) {
            return navigate({ search: buildSearch(`s=results`) });
          }
          return nextStepToShow
            ? navigate({
                search: buildSearch(`s=${getSlug(nextStepToShow.config)}&step-action=edit`)
              })
            : navigate({ search: buildSearch(`s=${getSlug(lastStep?.config)}`) });
        }

        return nextStepToShow
          ? navigate({ search: buildSearch(`s=${getSlug(nextStepToShow.config)}`) })
          : navigate({ search: buildSearch(`s=${getSlug(lastStep?.config)}`) });
      }
    } else if (step === 'prev') {
      return navigate(-1);
    } else {
      if (getStepNames().includes(step) || step === 'prevent') {
        return navigate({ search: buildSearch(`s=${step}`) });
      }
      return navigate({ search: buildSearch(`s=${getStepNames()[0]}`) });
    }
  };

  const getFlowLength = () =>
    mifSteps.filter(({ config }) => config.type !== 'info' && !config.showIf).length;

  useEffect(() => {
    if (!newAppointment.appointmentTypeId || newAppointment.status === 'created') {
      dispatch(clearAppointmentMif());
      navigate(PathName.Appointments, { replace: true });
    }

    getStructure(mifID)
      .unwrap()
      .then(({ data }) => {
        setMifSteps(data.configs.toSorted((a, b) => a.order - b.order));
      })
      .catch(handleRequestCatch)
      .finally(() => {
        if (redirectStep) {
          sessionStorage.setItem('redirectStep', redirectStep);
        }
        if (!stepFromQuery) {
          navigate(
            { search: `s=${getStepNames()[0]}` },
            {
              replace: true
            }
          );
        }
      });
  }, []);

  return {
    steps: mifSteps,
    currentQuestion: activeStep,
    description: data?.data?.description || '',
    loading: initialLoading,
    isUninitialized,
    activeStepIndex: progress(),
    flowLength: getFlowLength(),
    moveToStep,
    handleSubmit,
    isSubmittingResults,
    onLeaveFlow,
    currentQuestionIndex: getCurrentQuestionIndex()
  };
};
