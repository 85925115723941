import React, { ChangeEvent, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Common } from '@thecvlb/design-system';

import { useConnectKrogerIDMutation } from 'services/kroger/kroger';

import Loader from 'shared/Loader';
import { notifySuccess } from 'shared/Toast/Toast';

import { handleRequestCatch } from 'utils/helpers';

import { KrogerConnectIDProps } from './krogerConnectID.types';

import KrogerLogo from 'assets/images/kroger/kroger-locator-logo.svg?react';

const KrogerConnectID: React.FC<KrogerConnectIDProps> = ({ onContinue, onRefuse, loading }) => {
  const [params, setParams] = useSearchParams();
  const [krogerID, setKrogerID] = useState('');
  const [isAgree, setIsAgree] = useState(false);

  const [connect, { isLoading }] = useConnectKrogerIDMutation();

  const isConnectStep = params.get('subs') === 'connect';

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setKrogerID(e.target.value.slice(0, 12));
  };
  const handleSubmit = () => {
    connect({
      accountId: krogerID,
      termsAndConditionsSignedAt: new Date().toISOString()
    })
      .unwrap()
      .then(() => {
        notifySuccess('Success!');
        onContinue();
      })
      .catch(handleRequestCatch);
  };

  return (
    <>
      <Loader isVisible={!!loading} />
      {isConnectStep ? (
        <div className="flex flex-col items-center justify-between max-md:h-full md:gap-6">
          <div className="flex flex-col gap-6">
            <KrogerLogo className="m-auto" />
            <div className="flex flex-col gap-4 text-center">
              <h1 className="main-header text-primary-700">Great! Let’s connect your account</h1>
              <Common.Input
                disabled={!!loading}
                label="Enter your Kroger ID"
                maxLength={12}
                placeholder="481399328592"
                type="number"
                value={krogerID}
                onChange={handleChange}
              />
              <Common.Alert type="info" colorableBackground>
                <div className="flex flex-col items-start gap-px text-base">
                  <span className="mb-0.5 font-bold">Finding your Kroger ID </span>
                  <span className="ml-1.5">
                    1. Sign into your <span className="font-bold">Kroger.com</span> account
                  </span>
                  <span className="ml-1.5">
                    2. Go to <span className="font-bold">My Account</span>
                  </span>
                  <span className="ml-1.5">
                    3. Click on <span className="font-bold">Profile</span>
                  </span>
                  <span className="ml-1.5">
                    4. Find your <span className="font-bold">Alt ID</span>
                  </span>
                </div>
              </Common.Alert>
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <Common.Checkbox checked={isAgree} color="blue" onChange={() => setIsAgree(!isAgree)}>
              <span>
                I agree to the Kroger{' '}
                <a className="underline" href="#" target="_blank">
                  privacy consents
                </a>{' '}
                and{' '}
                <a className="underline" href="#" target="_blank">
                  terms and conditions
                </a>
                .
              </span>
            </Common.Checkbox>
            <Common.Button
              className="md:mx-auto"
              color="blue"
              disabled={!isAgree || krogerID.length < 9}
              isLoading={isLoading}
              fullWidthOnMobile
              onClick={handleSubmit}
            >
              Complete
            </Common.Button>
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-between max-md:h-full md:gap-6">
          <div className="flex flex-col gap-6">
            <div className="flex size-[150px] self-center rounded-full bg-[#DAE3FF] p-8">
              <KrogerLogo className="m-auto" />
            </div>
            <div className="flex flex-col gap-2 text-center">
              <h1 className="main-header text-primary-700">
                Would you spanke to connect your Kroger account
              </h1>
              <p className="text-sm text-primary-700">
                Connect your account for the best experience on the spanfeMD app.
              </p>
            </div>
          </div>
          <div className="flex w-full max-w-[200px] flex-col gap-4">
            <Common.Button
              className="!w-full"
              color="blue"
              fullWidthOnMobile
              onClick={() =>
                setParams((p) => {
                  p.set('subs', 'connect');
                  return p;
                })
              }
            >
              Yes, connect account
            </Common.Button>
            <Common.Button
              className="!w-full"
              color="white-alt"
              fullWidthOnMobile
              onClick={onRefuse}
            >
              No, thanks
            </Common.Button>
          </div>
        </div>
      )}
    </>
  );
};

export default KrogerConnectID;
