import React, { PropsWithChildren } from 'react';
import { useToggle } from 'react-use';
import { Common } from '@thecvlb/design-system';

import useAnalytics from 'hooks/useAnalytics';

const Collapse: React.FC<
  PropsWithChildren<{
    analyticsEvent?: string;
    iconClassName?: string;
    title: string;
    titleClassName?: string;
  }>
> = ({
  title,
  titleClassName = 'font-bold text-primary-700',
  iconClassName = 'text-primary',
  analyticsEvent,
  children
}) => {
  const logEvent = useAnalytics();
  const [isOpen, toggleIsOpen] = useToggle(false);

  const handleOpen = () => {
    toggleIsOpen();
    analyticsEvent && logEvent(analyticsEvent);
  };

  return (
    <div className={`border-b last:border-none ${isOpen && 'pb-4'}`}>
      <button className="flex w-full items-center justify-between gap-4 py-4" onClick={handleOpen}>
        <span className={`text-start ${titleClassName}`}>{title}</span>
        <Common.Icon
          className={`size-4 flex-none ${iconClassName}`}
          name={isOpen ? 'arrow-down' : 'arrow-right'}
        />
      </button>
      {isOpen && <p>{children}</p>}
    </div>
  );
};

export default Collapse;
