import React, { useRef } from 'react';
import { useClickAway, useToggle } from 'react-use';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Transition } from '@headlessui/react';
import { Common } from '@thecvlb/design-system';
import { IconProps } from '@thecvlb/design-system/lib/src/common';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { nanoid } from 'nanoid';

import { selectUser } from 'store';

import { useAppSelector } from 'hooks';
import { DateFormat } from 'utils/enums';

import { DocumentProps } from './document.types';

const Document: React.FC<DocumentProps> = ({
  onDownload,
  onDelete,
  document,
  onEdit,
  dataTestId,
  onClick,
  loading
}) => {
  const { profileImage } = useAppSelector(selectUser);
  const ref = useRef(null);
  const [isOpenMenu, setIsOpenMenu] = useToggle(false);

  const handleDownload = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (loading) return;
    onDownload?.();
    setIsOpenMenu();
  };

  const handleEdit = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (loading) return;
    onEdit?.();
    setIsOpenMenu();
  };

  const handleDelete = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (loading) return;
    onDelete?.();
    setIsOpenMenu();
  };

  const handleClickMenuIcon = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsOpenMenu();
  };

  const items = [
    {
      icon: 'download' as IconProps['name'],
      label: 'Download',
      onClick: handleDownload
    },
    ...(onEdit
      ? [
          {
            icon: 'pencil' as IconProps['name'],
            label: 'Edit details',
            onClick: handleEdit
          }
        ]
      : []),
    ...(onDelete
      ? [
          {
            icon: 'trash' as IconProps['name'],
            label: 'Delete',
            isRed: true,
            onClick: handleDelete
          }
        ]
      : [])
  ];

  useClickAway(ref, () => {
    isOpenMenu && setIsOpenMenu(false);
  });

  return (
    <div
      className="flex cursor-pointer flex-col justify-between gap-4 rounded-2xl border border-gray-200 bg-white p-4 md:h-[206px]"
      data-testid={dataTestId}
      onClick={onClick}
    >
      <div className="flex items-start gap-3">
        <Common.Icon
          className="text-primary md:size-[42px] md:rounded-lg md:bg-primary-50 md:p-[9px]"
          name="articles"
        />
        <div className="flex flex-1 flex-col gap-0.5">
          <p className="line-clamp-6 break-all text-base font-semibold">{document.fileName}</p>
          <div className="flex flex-wrap gap-2">
            {document.tags?.map((tag) => (
              <span
                className="w-fit rounded bg-gray-800/5 px-1 py-px text-xs text-gray-600"
                key={nanoid()}
              >
                {tag.name}
              </span>
            ))}
          </div>
        </div>
        {(onDownload || onDelete) && (
          <div className="relative" ref={ref}>
            <button onClick={handleClickMenuIcon}>
              <Common.Icon name="dots-vertical" />
            </button>
            <Transition
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
              show={isOpenMenu}
            >
              <div>
                <Common.ContextualMenu
                  className={classNames(
                    'min-w-min right-0',
                    isOpenMenu ? 'scale-y-100' : 'scale-y-0'
                  )}
                  items={items}
                  alt
                />
              </div>
            </Transition>
          </div>
        )}
      </div>
      <div className="flex items-center gap-1">
        <Common.ProfileImage size="sm" src={document.isUploadedByCurrentUser ? profileImage : ''} />
        <p className="text-sm text-gray">
          Created by {document.isUploadedByCurrentUser ? 'Me' : document.uploadedBy} •{' '}
          {dayjs(document.createdAt).format(DateFormat.MMM_DD_YYYY)}
        </p>
      </div>
    </div>
  );
};

export default Document;
