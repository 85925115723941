import { ChangeEvent, useEffect, useState } from 'react';
import { useDebounce } from 'react-use';
import { Common } from '@thecvlb/design-system';
import { DataItemProps } from '@thecvlb/design-system/lib/src/common';

import { useLazyGetMemberPricingQuery } from 'services/labTests/labTests';

import { MemberPricingItemProps } from 'store/labTests/labTests.types';

import MemberPricingTable from 'features/MemberPricingTable';
import Loader from 'shared/Loader';

import { BaseModalProps } from '../modal.types';

const MemberPricing: React.FC<BaseModalProps> = ({ isOpen, onClose }) => {
  const [getMemberPricing, { data, isLoading, isFetching }] = useLazyGetMemberPricingQuery();
  const [searchKey, setSearchKey] = useState('');
  const [pageNo, setPageNo] = useState(0);
  const tabs = [{ label: 'Quest' }];
  const [memberPricing, setMemberPricing] = useState<MemberPricingItemProps[]>([]);

  const handleGetData = () => {
    if (!isOpen) return;

    getMemberPricing({ labCompany: tabs[0].label, limit: 20, pageNo, searchKey });
    setPageNo(pageNo + 1);
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPageNo(0);
    setSearchKey(event.target.value);
  };

  const handleTabChange = (item: DataItemProps) => {
    // eslint-disable-next-line no-console
    console.log(item);
  };

  const handleUpdatedData = () => {
    if (!isFetching && data) {
      setMemberPricing((prev) => prev.concat(data.data));
    }
  };

  const handleUpdatedSearchKey = () => {
    setMemberPricing([]);
    handleGetData();
  };

  const handleUpdatedIsOpen = () => {
    if (isOpen) {
      setMemberPricing([]);
    } else {
      setPageNo(0);
      setSearchKey('');
    }
    handleGetData();
  };

  useDebounce(handleUpdatedSearchKey, 500, [searchKey]);

  useEffect(handleUpdatedIsOpen, [isOpen]);

  useEffect(handleUpdatedData, [data, isFetching]);

  return (
    <Common.Modal
      // dataTestId="member_pricing_modal"
      close={onClose}
      isOpen={isOpen}
      size="xl"
      zIndex={30}
    >
      <Loader isVisible={isLoading || isFetching} />
      <div className="mb-5 hidden items-center gap-4 md:flex">
        <h1 className="flex-1 text-2xl font-semibold text-primary" data-testid="modal_heading">
          LifeMD member pricing
        </h1>
        {/*<Patient.Button variant="alt">Learn more</Patient.Button>*/}
      </div>
      <div className="mb-4 flex flex-col justify-between gap-4 pr-0.5 md:flex-row md:items-center">
        <Common.Tabs data={tabs} type="line" onChange={handleTabChange} />
        <div>
          <Common.SearchInput
            dataTestId="search_input"
            placeholder="Search list"
            value={searchKey}
            onChange={handleInputChange}
          />
        </div>
      </div>
      <MemberPricingTable loadMore={handleGetData} memberPricing={memberPricing} />
    </Common.Modal>
  );
};

export default MemberPricing;
