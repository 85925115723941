import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { WmPage, WmSection } from 'services/wm/wm.types';

import { WeightManagementState } from './wm.types';

const initialState: WeightManagementState = {
  pages: [],
  sections: []
};

const wmSlice = createSlice({
  initialState,
  name: 'wm',
  reducers: {
    setPages: (state, action: PayloadAction<WmPage[]>) => {
      state.pages = action.payload;
    },
    setSections: (state, action: PayloadAction<WmSection[]>) => {
      state.sections = action.payload;
    }
  }
});

export const { setPages, setSections } = wmSlice.actions;

export default wmSlice.reducer;
