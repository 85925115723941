import { useEffect } from 'react';
import { useToggle } from 'react-use';
import { Common } from '@thecvlb/design-system/lib/src';

import { useDeleteDocumentMutation } from 'services/documents/documents';
import { useLazyGetMyAccountQuery } from 'services/myAccount/myAccount';

import { selectUser } from 'store';

import SkipUploadBodyPhoto from 'modals/SkipUploadBodyPhoto';
import AddBodyPhoto from 'modals/UploadBodyPhoto';
import FadeWrapper from 'shared/animationWrappers/FadeWrapper';

import { useAppSelector } from 'hooks';

import StepHeaderInfo from '../mifSteps/parts/StepHeaderInfo';

import { UploadBodyPhotoProps } from './uploadBodyPhoto.types';

const UploadBodyPhoto: React.FC<UploadBodyPhotoProps> = ({ moveToStep, mifStepsLength }) => {
  const [isOpenSkipUploadBodyPhoto, toggleIsOpenSkipUploadBodyPhoto] = useToggle(false);
  const [isOpenAddBodyPhoto, toggleIsOpenAddBodyPhoto] = useToggle(false);
  const [isSuccess, setIsSuccess] = useToggle(false);
  const { isAsyncSignup, bodyImage } = useAppSelector(selectUser);
  const [deleteDocument] = useDeleteDocumentMutation();
  const [getMyAccount] = useLazyGetMyAccountQuery();

  const handleCloseSkipUploadBodyPhoto = async (isSkip: boolean) => {
    toggleIsOpenSkipUploadBodyPhoto();

    if (isSkip) {
      const documentId = bodyImage?.documents[0]?._id;

      if (documentId) {
        await deleteDocument({ documentId });
      }

      await getMyAccount().unwrap();

      moveToStep('next');
    }
  };

  useEffect(() => {
    !bodyImage?.uploadRequired && setIsSuccess(!!bodyImage?.documents.length);
  }, [bodyImage]);

  return (
    <>
      <SkipUploadBodyPhoto
        isAsync={!!isAsyncSignup}
        isOpen={isOpenSkipUploadBodyPhoto}
        onClose={handleCloseSkipUploadBodyPhoto}
      />
      <AddBodyPhoto isOpen={isOpenAddBodyPhoto} onClose={toggleIsOpenAddBodyPhoto} />
      <FadeWrapper className="flex h-full flex-col">
        <StepHeaderInfo
          currentStep={`Step ${mifStepsLength} of ${mifStepsLength}`}
          dataTestId="header"
          stepSubtitle=""
          stepTitle="Please add a body photo of yourself"
        />
        <Common.Alert type="info" colorableBackground>
          This confidential image will only be used by your healthcare provider to determine the
          appropriate dosage and monitor your progress.
        </Common.Alert>
        <div className="relative mt-6 flex flex-col items-center rounded-xl bg-white p-3 shadow">
          {isSuccess && (
            <Common.Icon className="absolute right-2 top-2 text-green" name="check-circle" />
          )}
          <Common.Icon className="size-12 text-primary-400" name="accessibility" />
          <p className="text-mBase font-semibold" data-testid="body_photo_text">
            Body photo
          </p>
          <Common.Button
            className="mt-4"
            color="white"
            dataTestId="update_upload_btn"
            onClick={toggleIsOpenAddBodyPhoto}
          >
            {isSuccess ? 'Update' : 'Upload'}
          </Common.Button>
        </div>
        <div className="mt-auto flex w-full flex-col items-center gap-4 md:mt-6 md:gap-2">
          <Common.Button
            color="blue"
            disabled={!isSuccess}
            fullWidthOnMobile
            onClick={() => moveToStep('next')}
          >
            Continue
          </Common.Button>
          <Common.Button fullWidthOnMobile onClick={toggleIsOpenSkipUploadBodyPhoto}>
            I’ll do this later
          </Common.Button>
        </div>
      </FadeWrapper>
    </>
  );
};

export default UploadBodyPhoto;
